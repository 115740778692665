import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "revisionHeader",
    "reviewHeader",
    "changeFilesPanel",
    "revisionPanel",
    "revisionButton",
    "reviewButton",
    "currentFilesFieldset",
    "lastFilesFieldset",
  ]

  initialize() {
    this.newRevision = this.element.dataset.newRevision === "1"
    this.sync()
  }

  startRevision(event) {
    event.preventDefault()
    this.newRevision = true
    this.sync()
  }

  stopRevision(event) {
    event.preventDefault()
    this.newRevision = false
    this.sync()
  }

  selectAll() {
    this.lastFilesFieldsetTarget.querySelectorAll("input[type='checkbox']").forEach( cb => {
      cb.checked = true
    })
  }

  unselectAll() {
    this.lastFilesFieldsetTarget.querySelectorAll("input[type='checkbox']").forEach( cb => {
      cb.checked = false
    })
  }
  
  sync() {
    if (this.newRevision) {
      this.currentFilesFieldsetTarget.classList.add("hidden")
      this.lastFilesFieldsetTarget.classList.remove("hidden")
      this.revisionHeaderTarget.classList.remove("hidden")
      this.reviewHeaderTarget.classList.add("hidden")
      this.changeFilesPanelTarget.classList.add("hidden")
      this.revisionPanelTarget.classList.remove("hidden")
      this.revisionButtonTarget.style.display = "block"
      this.reviewButtonTarget.style.display = "none"

      // Enable revision fields
      this.revisionPanelTarget.querySelector("fieldset").disabled = false
    } else { // no revision, just a review
      this.currentFilesFieldsetTarget.classList.remove("hidden")
      this.lastFilesFieldsetTarget.classList.add("hidden")
      this.revisionHeaderTarget.classList.add("hidden")
      this.reviewHeaderTarget.classList.remove("hidden")
      this.changeFilesPanelTarget.classList.remove("hidden")
      this.revisionPanelTarget.classList.add("hidden")
      this.revisionButtonTarget.style.display = "none"
      this.reviewButtonTarget.style.display = "block"

      // Disable revision fields
      this.revisionPanelTarget.querySelector("fieldset").disabled = true
    }
  }
}
