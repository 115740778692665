import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
    "firstTime",
    "replacement",
    "reasonLabel",
    "reason",
    "lengthLabel",
    "submit",
  ]

  chooseFirstTime() {
    this.reasonLabelTarget.style.display = "none"
    this.reasonTarget.style.display = "none"
    this.lengthLabelTarget.style.display = "none"
    this.submitTarget.disabled = false
  }

  chooseReplacement() {
    this.reasonLabelTarget.style.display = "block"
    this.reasonTarget.style.display = "block"
    this.lengthLabelTarget.style.display = "block"
    this.updateReason()
  }

  updateReason() {
    this.updateLengthLabel(this.lengthLabelTarget, 100, this.reasonTarget.value.length)

    // Disable download button if the reason is too long
    let delta = 100 - this.reasonTarget.value.length
    debugger
    if (delta  > 0) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }
  
  updateLengthLabel(label, max, length) {
    var delta = max - length
    if (delta < 0) {
      label.textContent = "You have " + Math.abs(delta) + " characters too many."
      label.classList.add("alert-text")
    } else {
      label.textContent = "You have " + delta + " characters remaining."
      label.classList.remove("alert-text")
    }
  }
  
  download() {
    // Bail and issue an alert if there is no reason but should be
    if (this.replacementTarget.checked && this.reasonTarget.value.length === 0) {
      alert("You must specify a reason to update this report with OSHA.")
      return
    }

    let controller = this
    let req = new XMLHttpRequest()

    let establishmentClass = this.data.get("establishmentClass")
    var route = ""
    if (establishmentClass == "Establishment") {
      route = Routes.osha_injury_tracking_report_download_establishment_csv_path({
	account_id: this.data.get("accountId"),
	osha_injury_tracking_report_id: this.data.get("establishmentId"),
      })
    } else if (establishmentClass == "Division") {
      route = Routes.osha_injury_tracking_report_download_division_csv_path({
	account_id: this.data.get("accountId"),
	osha_injury_tracking_report_id: this.data.get("establishmentId"),
      })
    } else {
      alert("Unable to download at this time, try again later.")
    }
    
    req.open("POST", route)
    req.responseType = "blob"
    req.setRequestHeader("X-CSRF-Token", getCSRF())
    req.setRequestHeader("Content-Type", "application/json; charset=utf-8")
    req.addEventListener("load", data => {
      let blob = req.response
      // Slice removes surroudning quotes
      let filename = req.getResponseHeader("content-disposition").split("; ").find( el => el.match("filename=") ).replace(/filename=/, '').slice(1, -1)

      // Taken from https://stackoverflow.com/questions/51650258/prevent-open-file-prompt-in-microsoft-edge-using-angular-js
      // The click a link method works in every browser but Edge. It looks like Edge is overagressively
      // revoking the object url before the link visit happens, so when you try and trigger the link nothing is left.
      // Either way, this works, fuck you MS
      let isIE = false || document.documentMode || /Edge/.test(navigator.userAgent)
      if (isIE) {
        window.blur()
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        let a = document.createElement("a")
        let url = URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      }

      controller.reset()
    })
    req.send(JSON.stringify({"year": this.data.get("year"),
                             "reason-for-resubmit": this.reasonTarget.value}))
  }

  reset() {
    this.firstTimeTarget.checked = false
    this.replacementTarget.checked = false
    this.reasonLabelTarget.style.display = "none"
    this.reasonTarget.style.display = "none"
    this.reasonTarget.value = ""
    this.lengthLabelTarget.style.display = "none"
    this.submitTarget.disabled = true
  }

  initialize() {
    this.reset()
  }
}
