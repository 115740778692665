import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "category",
    "settingsContainer",
    "passFailSettings",
    "wholeNumberSettings",
    "decimalNumberSettings",
    "textSettings",
    "dateTimeSettings",
    "signatureSettings",
    "multipleSelectSettings",
    "noYesAside",
    "weightedScoreSettings",
  ]

  initialize() {
    this.syncCategory()
  }

  syncCategory() {
    let category = this.categoryTarget.value

    if (category == "") {
      this.settingsContainerTarget.classList.add("hidden")
    }  else {
      this.settingsContainerTarget.classList.remove("hidden")
    }
    
    // Toggle all settings as hidden
    this.passFailSettingsTarget.classList.add("hidden")
    this.wholeNumberSettingsTarget.classList.add("hidden")
    this.decimalNumberSettingsTarget.classList.add("hidden")
    this.textSettingsTarget.classList.add("hidden")
    this.dateTimeSettingsTarget.classList.add("hidden")
    this.signatureSettingsTarget.classList.add("hidden")
    this.multipleSelectSettingsTarget.classList.add("hidden")
    if (this.hasWeightedScoreSettingsTarget) {
      this.weightedScoreSettingsTarget.classList.add("hidden")
    }
	
    switch (category) {
    case "passFail":
      this.passFailSettingsTarget.classList.remove("hidden")
      if (this.hasWeightedScoreSettingsTarget) {
	this.weightedScoreSettingsTarget.classList.remove("hidden")
      }
      break
    case "wholeNumber":
      this.wholeNumberSettingsTarget.classList.remove("hidden")
      if (this.hasWeightedScoreSettingsTarget) {
	this.weightedScoreSettingsTarget.classList.remove("hidden")
      }
      break
    case "decimalNumber":
      this.decimalNumberSettingsTarget.classList.remove("hidden")
      if (this.hasWeightedScoreSettingsTarget) {
	this.weightedScoreSettingsTarget.classList.remove("hidden")
      }
      break
    case "text":
      this.textSettingsTarget.classList.remove("hidden")
      break
    case "dateTime":
      this.dateTimeSettingsTarget.classList.remove("hidden")
      break
    case "signature":
      this.signatureSettingsTarget.classList.remove("hidden")
      break
    case "multipleSelect":
      this.multipleSelectSettingsTarget.classList.remove("hidden")
      break
    }
  }

  toggleNoYesAside(el) {
    if (el.target.value === "noYes") {
      this.noYesAsideTarget.classList.remove("hidden")
    } else {
      this.noYesAsideTarget.classList.add("hidden")
    }
  }
}
