import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["banner",
                    "caseNumber",
                    "employee",
                    "employeeFirstName",
                    "employeeMiddleName",
                    "employeeLastName",
                    "employeeJobTitle",
                    "establishment",
                    "occurredOn",
                    "whereEventOccurred",
                    "injuryType",
                    "summaryOfEvent",
                    "didEmployeeDieYes",
                    "didEmployeeDieNo",
                    "daysAwayFromWork",
                    "daysOnJobRestrictions"]

  presenceComplete(el) {
    return el.value === "" ? false : true
  }

  boolPresenceComplete(yesEl, noEl) {
    return yesEl.checked || noEl.checked
  }

  changePresenceComplete(event) {
    this.updatePresenceComplete(event.target)
    this.checkComplete()
  }

  changeEmployee() {
    if (this.employeeTarget.value) {
      fetch(Routes.employee_path(this.employeeTarget.value, {account_id: this.element.dataset.accountId}), {credentials: "same-origin",
                                                                                                            headers: {
                                                                                                              "X-CSRF-Token": $("meta[name=csrf-token]").attr("content")}})
        .then(response => response.text())
        .then(json => {
          let employee = JSON.parse(json)
          this.employeeFirstNameTarget.value = employee.first_name
          this.employeeMiddleNameTarget.value = employee.middle_name
          this.employeeLastNameTarget.value = employee.last_name
          this.employeeJobTitleTarget.value = employee.job_title

          ;[this.employeeTarget,
            this.employeeFirstNameTarget,
            this.employeeLastNameTarget,
            this.employeeJobTitleTarget].forEach(this.updatePresenceComplete, this)

          this.checkComplete()
        })
    } else {
      this.employeeFirstNameTarget.value = ""
      this.employeeMiddleNameTarget.value = ""
      this.employeeLastNameTarget.value = ""
      this.employeeJobTitleTarget.value = ""

      ;[this.employeeTarget,
        this.employeeFirstNameTarget,
        this.employeeLastNameTarget,
        this.employeeJobTitleTarget].forEach(this.updatePresenceComplete, this)

      this.checkComplete()
    }
  }

  changeDidEmployeeDie() {
    this.updateBoolPresenceComplete(this.didEmployeeDieYesTarget, this.didEmployeeDieNoTarget)
    this.checkComplete()
  }

  // display methodsn
  updatePresenceComplete(el) {
    if (this.presenceComplete(el)) {
      el.classList.remove("incomplete")
    } else {
      el.classList.add("incomplete")
    }
  }

  updateBoolPresenceComplete(yesEl, noEl) {
    if (yesEl.checked || noEl.checked) {
      yesEl.classList.remove("incomplete")
      noEl.classList.remove("incomplete")
    } else {
      yesEl.classList.add("incomplete")
      noEl.classList.add("incomplete")
    }
  }

  checkComplete() {
    if (this.presenceComplete(this.caseNumberTarget) &&
        this.presenceComplete(this.employeeTarget) &&
        this.presenceComplete(this.employeeFirstNameTarget) &&
        this.presenceComplete(this.employeeLastNameTarget) &&
        this.presenceComplete(this.employeeJobTitleTarget) &&
        this.presenceComplete(this.establishmentTarget) &&
        this.presenceComplete(this.occurredOnTarget) &&
        this.presenceComplete(this.whereEventOccurredTarget) &&
        this.presenceComplete(this.injuryTypeTarget) &&
        this.presenceComplete(this.summaryOfEventTarget) &&
        this.boolPresenceComplete(this.didEmployeeDieYesTarget, this.didEmployeeDieNoTarget) &&
        this.presenceComplete(this.daysAwayFromWorkTarget) &&
        this.presenceComplete(this.daysOnJobRestrictionsTarget)) {
      this.bannerTarget.style.visibility = "hidden"
    } else {
      this.bannerTarget.style.visibility = "visible"
    }
  }

  initialize() {
    [this.caseNumberTarget,
     this.employeeTarget,
     this.employeeFirstNameTarget,
     this.employeeLastNameTarget,
     this.employeeJobTitleTarget,
     this.establishmentTarget,
     this.occurredOnTarget,
     this.whereEventOccurredTarget,
     this.injuryTypeTarget,
     this.summaryOfEventTarget,
     this.daysAwayFromWorkTarget,
     this.daysOnJobRestrictionsTarget].forEach(this.updatePresenceComplete, this)
    this.changeDidEmployeeDie()

    this.checkComplete()
  }
}
