import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["experienceLevelSelect",
		    "jobQualificationSelect"]

  initialize() {
    this.sync()
  }

  sync() {
    let tracksExperience = this.jobQualificationSelectTarget.querySelector(":checked").dataset.trackExperience

    if (tracksExperience == "true") {
      this.experienceLevelSelectTarget.style.display = "block"
    } else {
      this.experienceLevelSelectTarget.style.display = "none"
    }
  }
}
