import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
	"addButton",
    "employeeSelect",
  ]

  addDirectReport(event) {
    event.preventDefault()
    fetch(Routes.add_direct_report_employee_path(this.data.get("accountId"), this.data.get("employeeId"), {direct_report_id: this.employeeSelectTarget.value}), {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": getCSRF()}})
      .then( response => {
        if (response.ok) {
          return response.text()
        }
        throw new Error("something went wrong")
      })
      .then ( text => {
        let range = document.createRange()
        let frag = range.createContextualFragment(text)

        let directReportsList = frag.querySelector("ul")
        document.querySelector(".js-direct-reports-list").innerHTML = directReportsList.innerHTML

        let employeeSelect = frag.querySelector("select")
        document.querySelector(".js-direct-reports-select").innerHTML = employeeSelect.innerHTML
        $(this.employeeSelectTarget).trigger("chosen:updated")

        this.employeeSelectTarget.value = ""
        this.addButtonTarget.disabled = true
      })
      .catch( error => {
        console.log("Server error occurred")
      })
    return false
  }

  removeDirectReport(event) {
    event.preventDefault()
    if (confirm("Are you sure you want to remove " + event.target.dataset.directReportName + " from this employee's direct reports?")) {
      let removeButton = event.target
      fetch(Routes.remove_direct_report_employee_path(this.data.get("accountId"), this.data.get("employeeId"), {direct_report_id: removeButton.dataset.employeeId}), {
        method: "PATCH",
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": getCSRF()}})
        .then( response => {
          if (response.ok) {
            return response.text()
          }
          throw new Error("something went wrong")
        })
        .then( text => {
          let range = document.createRange()
          let frag = range.createContextualFragment(text)

          let employeeSelect = frag.querySelector("select")
          document.querySelector(".js-direct-reports-select").innerHTML = employeeSelect.innerHTML
          $(this.employeeSelectTarget).trigger("chosen:updated")

          $(removeButton).closest("li").remove()
        })
        .catch( error => {
          console.log("Server error occurred")
        })
    }
    return false
  }

  selectEmployee() {
    if (this.employeeSelectTarget.value === "") {
      this.addButtonTarget.disabled = true
    } else {
      this.addButtonTarget.disabled = false
    }
  }

  initialize() {
    this.addButtonTarget.disabled = true
  }
}
