import { Controller } from "stimulus"
import { parseDate } from "parse_date"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = ["banner",
                    "caseNumber",
                    "takenToMedicalFacilityYes",
                    "takenToMedicalFacilityNo",
                    "establishment",
                    "occurredOn",
                    "timeOfEventHour",
                    "timeOfEventMinute",
                    "timeOfEventCBD",
                    "didEmployeeDieYes",
                    "didEmployeeDieNo",
                    "dateOfDeath"]

  // Actions
  changeTimeOfEventCBD() {
    if (this.timeOfEventCBDTarget.checked) {
      this.timeOfEventHourTarget.value = ""
      this.timeOfEventMinuteTarget.value = ""
      this.timeOfEventHourTarget.disabled = true
      this.timeOfEventMinuteTarget.disabled = true
      this.timeOfEventHourTarget.classList.remove("incomplete")
      this.timeOfEventMinuteTarget.classList.remove("incomplete")
    } else {
      this.timeOfEventHourTarget.disabled = false
      this.timeOfEventMinuteTarget.disabled = false
    }
    $(this.timeOfEventHourTarget).trigger("chosen:updated")
    $(this.timeOfEventMinuteTarget).trigger("chosen:updated")
  }

  changeMedicalInfo() {
    if (this.takenToMedicalFacilityYesTarget.checked) {
      document.querySelector(".medical-facility-fields").style.display = "block"
    } else {
      document.querySelector(".medical-facility-fields").style.display = "none"
    }
  }

  changeDidEmployeeDie() {
    if (this.didEmployeeDieYesTarget.checked) {
      document.querySelector(".date-of-death-fields").style.display = "block"
    } else {
      $(this.dateOfDeathTarget).datepicker("setDate", null)
      document.querySelector(".date-of-death-fields").style.display = "none"
    }
  }

  // display methods
  initialize() {
    this.changeMedicalInfo()
    this.changeDidEmployeeDie()
    this.changeTimeOfEventCBD()
  }
}
