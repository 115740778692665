// We use this file to force dialogs run run before datepickers.
// The dialog code creates an in-memory version of a dialog form to use to
// automatically reset form values on redisplay. Every time the dialog
// is hidden, this in-memory form is reinserted into the DOM and the datepicker
// code rerun. In order for this to work, Vue must be run after the dialog cache
// is created.

import { buildJqueryDatepicker } from "datepickers"
import { buildDialog } from "dialogs"
import { buildChosen } from "chosen"
import { previewUpload, previewAnnotatedUpload, buildAnnotatedDialog } from "direct_upload"
import { buildAccordion, buildAnswerAccordion } from "accordions"

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("[data-dialog]").forEach(buildDialog)
  document.querySelectorAll("[data-annotated-dialog]").forEach(buildAnnotatedDialog)
  document.querySelectorAll(".datepicker").forEach(buildJqueryDatepicker)
  document.querySelectorAll(".accordion").forEach(buildAccordion)
  document.querySelectorAll(".answer-accordion").forEach(buildAnswerAccordion)
  document.querySelectorAll("select:not(.skip-chosen)").forEach(buildChosen)
  document.querySelectorAll("input[type='file'].preview-upload").forEach(previewUpload)
  document.querySelectorAll("input[type='file'].preview-annotated-upload").forEach(previewAnnotatedUpload)
})
