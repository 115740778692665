import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.element.addEventListener("keydown", event => {
      if (event.code == "Enter") {
	event.preventDefault()
	event.target.closest("form").submit()
      }
    })
  }
}
