import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = ["witness",
                    "supervisor"]

  changeWitness() {
    if (this.witnessTarget.value === "") {
      let $supervisor = $(this.supervisorTarget)
      $supervisor.val("")
      $supervisor.trigger("chosen:updated")

    } else {
      fetch(Routes.employee_path(this.witnessTarget.value, {account_id: this.element.dataset.accountId}), {credentials: "same-origin",
                                                                                                           headers: {
                                                                                                             "X-CSRF-Token": getCSRF()}})
        .then(response => response.text())
        .then(json => {
          let employee = JSON.parse(json)
          let $supervisor = $(this.supervisorTarget)
          $supervisor.val(employee.supervisor_id)
          $supervisor.trigger("chosen:updated")
        })
    }
  }
}
