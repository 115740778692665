import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "accountId",
    "establishment",
    "from",
    "to",
  ]

  accountId() {
    return this.accountIdTarget.value
  }

  reportOptions(format) {
    return {
      "from": this.fromTarget.value,
      "to": this.toTarget.value,
      "establishment_or_division": this.establishmentTarget.value,
      "format": format,
    }
  }
  
  printReport() {
    location.href = Routes.cost_of_incidents_report_path(this.accountId(), this.reportOptions("pdf"))
  }

  printCSV() {
    location.href = Routes.cost_of_incidents_report_path(this.accountId(), this.reportOptions("csv"))
  }
}
