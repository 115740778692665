import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
	this.previousValid = ""
	this.element.addEventListener('input', e => {
	  if (e.target.value) {
		if (/^\d*\.?\d*$/.test(e.target.value)) {
		  // valid decimal number
		  this.previousValid = e.target.value
		} else {
		  // Undo the attempted change
		  e.target.value = this.previousValid
		}
	  }
	})
  }
}
