import { Controller } from "stimulus"
import { buildChosen } from "chosen"
import { buildJqueryDatepicker } from "datepickers"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = ["employeeSelect"]

  openDialog(e) {
    e.preventDefault()
    this.$employeeDialog.dialog("open")
  }

  initialize() {
    // Copy the form component of the dialog, then create a template of it for future refreshes
    this.formTemplate = document.createDocumentFragment()
    this.formTemplate.appendChild($("#add-employee-dialog form.new_employee").get(0).cloneNode(true))

    $("#add-employee-dialog").dialog({
      autoOpen: false,
      draggable: false,
      modal: true,
      closeText: "X",
      width: 720,
      close: (event, ui) => {
        // Replace with template so builders are run on the newly inserted form
        document.querySelector("form.new_employee").replaceWith(this.formTemplate.cloneNode(true))
        let $employeeForm = document.querySelector("form.new_employee")

        // rerun chosen code
        $employeeForm.querySelectorAll("select").forEach(buildChosen)

        // rerun datepicker code
        $employeeForm.querySelectorAll(".datepicker").forEach(buildJqueryDatepicker)
      }
    })

    // Capture the employee dialog after running the jqueryUI initializer, as the initializer
    // moves the element.
    this.$employeeDialog = $("#add-employee-dialog")

    // We cannot use a stimulus action to handle closing because the html for the form is outside
    // the scope of the controller. We must do this because the dialog contains a form, and that form
    // must be outside of the primary form
    this.$employeeDialog.on("click", ".close", e => {
      e.preventDefault()
      this.$employeeDialog.dialog("close")
    })

    // Listen to the bubbling submit button on the dialog since the form itself will be
    // replaced
    this.$employeeDialog.on("submit", e => {
      e.preventDefault()

      // Because the submit event handler forces this to the target element which then f's up
      // this within the fetch promise response handlers, so explicitly capture the select we
      // want to update
      var employeeSelect = this.employeeSelectTarget
      var employeeDialog = this.$employeeDialog

      let form = this.$employeeDialog.find("form")
      let employee = {
        first_name: form.find("#employee_first_name").val(),
        middle_initial: form.find("#employee_middle_initial").val(),
        last_name: form.find("#employee_last_name").val(),
        identifier: form.find("#employee_identifier").val(),
        street_address: form.find("#employee_street_address").val(),
        locality: form.find("#employee_locality").val(),
        region: form.find("#employee_region").val(),
        postal_code: form.find("#employee_postal_code").val(),
        birth_date: form.find("#employee_birth_date").val(),
        hire_date: form.find("#employee_hire_date").val(),
        job_title: form.find("#employee_job_title").val(),
        supervisor_id: form.find("#employee_supervisor_id").val(),
        gender: form.find("#employee_gender").val()
      }

      fetch(Routes.create_for_select_employees_path({account_id: this.element.dataset.accountId}), {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": getCSRF()
        },
        body: JSON.stringify({"employee": employee})
      })
        .then(response => {
          if (response.status === 201) {
            response.text().then( html => {
              employeeSelect.innerHTML = $(html)[0].innerHTML

              // This will redraw the chosen select to keep it in sync with the hidden select
              $(employeeSelect).trigger("chosen:updated")

              // Trigger the system change event to notfiy any parent controller that the employee has changed
              employeeSelect.dispatchEvent(new Event("change"))

              employeeDialog.dialog("close")
            })
          } else if (response.status === 400) {
            response.text().then( html => {
              employeeDialog.find("form").get(0).innerHTML = $(html).find("form")[0].innerHTML
              employeeDialog.find("form select").each( (i, el) => { buildChosen(el) })
              employeeDialog.find("form .datepicker").each( (i, el) => { buildJqueryDatepicker(el) })
            })
          }
        })
    })
  }
}
