import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "safetySummarySubscribe",
    "frequencySelect",
  ]

  toggleSafetySummary() {
    this.sync()
  }

  sync() {
    if (this.safetySummarySubscribeTarget.checked) {
      this.frequencySelectTarget.style.display = "block"
    } else {
      this.frequencySelectTarget.style.display = "none"
    }
  }

  initialize() {
    this.sync()
  }
}
