import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "accountId",
    "establishment",
  ]

  printReport() {
    location.href = Routes.ytd_reports_path(this.accountIdTarget.value, {
      establishment_or_division: this.establishmentTarget.value,
      format: "pdf",
    })
  }
}
