import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
	this.element.addEventListener('input', (e) => {
	  if (e.target.value) {
		e.target.value = e.target.value.replace(/[^\d]/g, '')
	  }
	})
  }
}
