import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "notifyAllInspections",
    "notifyFailingInspections",
    "allInspections",
    "failingInspections",
  ]

  sync() {
	this.notifyAllInspectionsTarget.value = JSON.stringify(this.getSelectedRadioValues(this.allInspectionsTargets))
	this.notifyFailingInspectionsTarget.value = JSON.stringify(this.getSelectedRadioValues(this.failingInspectionsTargets))
  }

  getSelectedRadioValues(targets) {
	return targets.filter( rb => rb.checked ).map( rb => parseInt(rb.value) )
  }
}
