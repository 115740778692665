import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "accountId",
    "from",
    "to",
    "establishment",
    "employeeInputType",
    "status",
  ]

  status() {
    return this.statusTargets.find( el => el.checked ).value
  }
  
  accountId() {
    return this.accountIdTarget.value
  }

  linkParams(format) {
    return ({
      "from": this.fromTarget.value,
      "to": this.toTarget.value,
      "establishment_or_division": this.establishmentTarget.value,
      "employee_input_type": this.employeeInputTypeTarget.value,
      "status": this.status(),
      "format": format,
    })
  }
  
  printReport() {
    location.href = Routes.employee_input_report_path(this.accountId(), this.linkParams("pdf"))
  }

  downloadCsv() {
    location.href = Routes.employee_input_report_path(this.accountId(), this.linkParams("csv"))
  }
}
