import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  submit(event) {
    const target = event.target
    target.disabled = true

    fetch(target.dataset.path, {
      method: "PATCH",
      credentials: "same-origin",
      headers: {
	"Accept": "application/json",
	"Content-Type": "application/json; charset=utf-8",
	"X-CSRF-Token": getCSRF()
      },
      body: JSON.stringify({
	[target.dataset.modelName]: {
	  [target.dataset.attributeName]: target.value,
	}
      })
    })
      .then(response => {
	if (response.ok) {
	  return response.json()
	} else {
	  throw new Error("Server error")
	}
      })
      .then(json => {
	const newValue = json[target.dataset.attributeName]

	if (newValue == target.dataset.originalValue) {
	  // no change happened, must have been server error
	  alert(`${target.value} was not valid.`)
	  target.value = target.dataset.originalValue
	} else {
	  // update succeeded, update originalValue
	  target.dataset.originalValue = newValue
	}
      })
      .catch(error => {
	alert("Error attempting to update, try again later.")
	target.value = target.dataset.originalValue
	target.focus()
      })
      .finally(() => {
	target.disabled = false
      })
  }
}
