import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "actionsContainer"];

  // The `dialog` widget moves the hiddenInputContainer element outside of the
  // scope of the overdueInspectionsController, so we can't reference it as
  // a normal Stimulus target.
  _getHiddenInputContainers() {
    return Array.from(document.querySelectorAll('[data-target="overdue-inspections.hiddenInputContainer"]'));
  }

  initialize() {}

  connect() {
    this._updateActionsContainer(0);
  }

  toggle(_e) {
    this._handleCheckboxUpdate();
  }

  toggleAllCheckboxes(e) {
    const checked = e.target.checked;
    this.checkboxTargets.forEach(function (el) {
      el.checked = checked;
    });

    this._handleCheckboxUpdate();
  }

  _handleCheckboxUpdate() {
    const containers = this._getHiddenInputContainers();

    containers.forEach(function(container) {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    })

    const ids = this._checkedIds()
    
    ids.forEach(function (id) {
      const i = document.createElement("input");
      i.type = "hidden";
      i.name = "ids[]";
      i.value = id;

      containers.forEach(function(container) {
        container.append(i.cloneNode());
      })
    });

    this._updateActionsContainer(ids.length);
    this._updateDialog(ids.length);
  }

  _checkedIds() {
    var ids = [];
    this.checkboxTargets.forEach(function (el) {
      if (el.checked) {
        const id = el.dataset["id"];
        ids.push(id);
      }
    });
    return ids;
  }

  _updateActionsContainer(checkedCount) {
    const el = this.actionsContainerTarget;

    if (checkedCount > 0) {
      el.classList.remove("hidden");
    } else {
      el.classList.add("hidden");
    }

    el.querySelector("span.checked_count").textContent = String(checkedCount);
  }

  _updateDialog(checkedCount) {
    const dialogElement = document.querySelector("#remove-inspections-form");

    if (checkedCount > 1) {
      dialogElement.querySelector("main[data-version='plural'").removeAttribute("hidden");
      dialogElement.querySelector("main[data-version='singular'").setAttribute("hidden", "hidden");
    }
    else {
      dialogElement.querySelector("main[data-version='singular'").removeAttribute("hidden");
      dialogElement.querySelector("main[data-version='plural'").setAttribute("hidden", "hidden");
    }
  }
}
