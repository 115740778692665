export function buildAccordion(el) {
  var $el = $(el)

  let accordionOpts = {
	collapsible: true,
  }

  // Determine which accordion link is active, start with that accordion content pane
  // expanded

  // Currently we only use the accordion for the reports menu, so this code is heavily
  // linked to that DOM structure. For a more generic solution, rewrite this.
  var active = $el.find(".active")
  var activeContainer = active.closest("div")
  var activeIndex = $el.find("div").index(activeContainer)

  // activeIndex of -1 means it was not found
  accordionOpts.active = activeIndex >= 0 ? activeIndex : false

  $el.accordion(accordionOpts)
}

export function buildAnswerAccordion(el) {
  var $el = $(el)

  $el.accordion({
	active: false,
	collapsible: true,
  })
}
