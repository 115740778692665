import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "valueInput"];

  handleChange(_event) {
    const values = this.checkboxTargets
      .filter((t) => t.checked)
      .map((t) => t.value);

    this.valueInputTarget.value = JSON.stringify(values);
  }
}
