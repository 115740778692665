import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
    "firstTime",
    "replacement",
    "reasonLabel",
    "reason",
    "lengthLabel",
    "submit",
  ]

  chooseFirstTime() {
    this.reasonLabelTarget.style.display = "none"
    this.reasonTarget.style.display = "none"
    this.lengthLabelTarget.style.display = "none"
    this.submitTarget.disabled = false
  }

  chooseReplacement() {
    this.reasonLabelTarget.style.display = "block"
    this.reasonTarget.style.display = "block"
    this.lengthLabelTarget.style.display = "block"
    this.updateReason()
  }

  updateReason() {
    this.updateLengthLabel(this.lengthLabelTarget, 100, this.reasonTarget.value.length)

    // Disable submit button if the reason is too long or empty
    let extraChars = this.reasonTarget.value.length - 100
    if (extraChars  > 0 || this.reasonTarget.value.length == 0) {
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.disabled = false
    }
  }
  
  updateLengthLabel(label, max, length) {
    var delta = max - length
    if (delta < 0) {
      label.textContent = "You have " + Math.abs(delta) + " characters too many."
      label.classList.add("alert-text")
    } else {
      label.textContent = "You have " + delta + " characters remaining."
      label.classList.remove("alert-text")
    }
  }

  reset() {
    this.firstTimeTarget.checked = false
    this.replacementTarget.checked = false
    this.reasonLabelTarget.style.display = "none"
    this.reasonTarget.style.display = "none"
    this.reasonTarget.value = ""
    this.lengthLabelTarget.style.display = "none"
    this.submitTarget.disabled = true
  }

  initialize() {
    this.reset()
  }
}
