export function parseDate(ds) {
  if (ds === null) {
    return null
  }

  let year = ds.substr(0,4)
  let month = parseInt(ds.substr(5,2)) - 1
  let day = ds.substr(8,2)
  return new Date(year, month, day)
}
