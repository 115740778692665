import { Controller } from "stimulus"
import { buildChosen } from "chosen"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
    "medicalFacilitySelect",
  ]

  openDialog(e) {
    e.preventDefault()
    this.$medicalFacilityDialog.dialog("open")
  }

  initialize() {
    // Copy the form component of the dialog, then create a template of it for future refreshes
    this.formTemplate = document.createDocumentFragment()
    this.formTemplate.appendChild($("#add-medical-facility-dialog form.new_medical_facility").get(0).cloneNode(true))

    $("#add-medical-facility-dialog").dialog({
      autoOpen: false,
      draggable: false,
      modal: true,
      closeText: "X",
      width: 720,
      close: (event, ui) => {
        // Replace with template so builders are run on the newly inserted form
        document.querySelector("form.new_medical_facility").replaceWith(this.formTemplate.cloneNode(true))
        let $medicalFacilityForm = document.querySelector("form.new_medical_facility")

        // rerun chosen code
        $medicalFacilityForm.querySelectorAll("select").forEach(buildChosen)
      }
    })

    // Capture the medicalFacility dialog after running the jqueryUI initializer, as the initializer
    // moves the element.
    this.$medicalFacilityDialog = $("#add-medical-facility-dialog")

    // We cannot use a stimulus action to handle closing because the html for the form is outside
    // the scope of the controller. We must do this because the dialog contains a form, and that form
    // must be outside of the primary form
    this.$medicalFacilityDialog.on("click", ".close", e => {
      e.preventDefault()
      this.$medicalFacilityDialog.dialog("close")
    })

    // Listen to the bubbling submit button on the dialog since the form itself will be
    // replaced
    this.$medicalFacilityDialog.on("submit", e => {
      e.preventDefault()

      // Because the submit event handler forces `this` to the target element which then f's up
      // `this` within the fetch promise response handlers, so explicitly capture the select we
      // want to update
      var medicalFacilitySelect = this.medicalFacilitySelectTarget
      var medicalFacilityDialog = this.$medicalFacilityDialog

      let form = this.$medicalFacilityDialog.find("form")
      let medicalFacility = {
	name: form.find("#medical_facility_name").val(),
	street_address: form.find("#medical_facility_street_address").val(),
	locality: form.find("#medical_facility_locality").val(),
	region: form.find("#medical_facility_region").val(),
	postal_code: form.find("#medical_facility_postal_code").val(),
      }

      fetch(Routes.create_for_select_medical_facilities_path({account_id: this.element.dataset.accountId}), {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "X-CSRF-Token": getCSRF()
        },
        body: JSON.stringify({"medical_facility": medicalFacility})
      })
        .then(response => {
          if (response.status === 201) {
            response.text().then( html => {
              medicalFacilitySelect.innerHTML = $(html)[0].innerHTML

              // This will redraw the chosen select to keep it in sync with the hidden select
              $(medicalFacilitySelect).trigger("chosen:updated")

              // Trigger the system change event to notfiy any parent controller that the medicalFacility has changed
              medicalFacilitySelect.dispatchEvent(new Event("change"))

              medicalFacilityDialog.dialog("close")
            })
          } else if (response.status === 400) {
            response.text().then( html => {
              medicalFacilityDialog.find("form").get(0).innerHTML = $(html).find("form")[0].innerHTML
              medicalFacilityDialog.find("form select").each( (i, el) => { buildChosen(el) })
            })
          }
        })
    })
  }
}
