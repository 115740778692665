import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "source",
    "hiddenView",
  ]

  initialize() {
    this.toggle()
  }

  toggle(event) {
    var hide
    switch(this.sourceTarget.type) {
    case "checkbox":
      hide = !this.sourceTarget.checked
      break
    default:
      hide = (this.sourceTarget.value == "")
      break
    }
    
    this.hiddenViewTargets.forEach( view => {
      if (hide) {
	view.style.display = "none"
      } else {
	view.style.display = "block"
      }
    })
  }
}

