// ClassroomSessions have two time related attributes,
// from and to. Since a ClassroomSession is a single day, we do not
// want to force the user to pick a day twice. However this means
// that we need to keep the day for from and to synced together.
// We do this by represnting both as a standard rails datime_select
// widget which has hidden
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "date", // assumed to be string of format mm/dd/yy
  ] 

  connect() {
    this.syncDay()
  }
  
  syncDay() {
	if (this.hasDateTarget) {
      // Would be nice to use targets here, but rails datepicker widget won't
      // let us assign data attributes to the selects
      let [year, month, day] = this.ymd()
      this.fromYear().value = year
      this.fromMonth().value = month
      this.fromDay().value = day
      this.toYear().value = year
      this.toMonth().value = month
      this.toDay().value = day
	}
  }

  ymd() {
    let date = this.dateTarget.value.split('/').map( s => s.replace(/^0+/, '') )
    let year = date[2].replace(/^0+/, '')
    let month = date[0].replace(/^0+/, '')
    let day = date[1].replace(/^0+/, '')
    return [year, month, day]
  }

  fromYear() {
    return this.element.querySelector("[name*='from(1i)']")
  }
  
  fromMonth() {
    return this.element.querySelector("[name*='from(2i)']")
  }
  
  fromDay() {
    return this.element.querySelector("[name*='from(3i)']")
  }
  
  toYear() {
    return this.element.querySelector("[name*='to(1i)']")
  }
  
  toMonth() {
    return this.element.querySelector("[name*='to(2i)']")
  }
  
  toDay() {
    return this.element.querySelector("[name*='to(3i)']")
  }
}
