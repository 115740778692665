import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
	"fileInput",
	"submitButton",
  ]

  connect() {
    this.fileInputChanged()
  }
  
  fileInputChanged() {
    if (this.fileInputTarget.value === "") {
      this.submitButtonTarget.disabled = true
    } else {
      this.submitButtonTarget.disabled = false
    }
  }
}
