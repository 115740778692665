import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "valueContainer",
    "naCheckbox",
    "additionalValues",
  ]

  connect() {
    const enableFields = !this.naCheckboxTarget.checked
    if (enableFields) {
      this.valueContainerTarget.removeAttribute("disabled")
    } else {
      this.valueContainerTarget.setAttribute("disabled", true)
    }
  }

  toggleFields(event) {
    const enableFields = !event.target.checked
    if (enableFields) {
      this.valueContainerTarget.removeAttribute("disabled")
    } else {
      this.valueContainerTarget.setAttribute("disabled", true)
    }
  }
}
