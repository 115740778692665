import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    document.querySelectorAll("canvas[data-graph-data]").forEach( function(el) {
      var rawData = JSON.parse(el.dataset.graphData)
      var ctx = el.getContext("2d")
      var values = rawData.map( function(rawElement) {
	return rawElement[1]
      })
      var labels = rawData.map( function(rawElement) {
	return rawElement[0]
      })
      var datasets = [{
	data: values,
	borderColor: "#368bee",
	label: el.dataset.graphLabel || "Your Rate"
      }];

      var comparisonValue = parseFloat(el.dataset.comparisonValue);
      if (comparisonValue || comparisonValue == 0) {
	var comparisonData = values.map( function(x) {
	  return comparisonValue;
	})
	datasets.push({
	  data: comparisonData,
	  pointRadius: 0,
	  borderColor: "#ff8685",
	  label: el.dataset.comparisonLabel || "Industry Rate"
	})
      }
      
      var chart = new Chart(ctx, {
	type: "line",
	data: {
	  datasets: datasets,
	  labels: labels
	},
	options: {
	  scales: {
	    yAxes: [{
	      ticks: {
		beginAtZero: true
	      }
	    }]
	  },
	  animation: {
	    duration: 0
	  },
	  elements: {
	    line: {
	      tension: 0,
	      fill: false
	    },
	    point: {
	      pointRadius: 8
	    }
	  }
	}
      })
    })
  }
}
