import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
	"multipleColumnsContainer",
	"singleColumnContainer",
	"multipleRadio",
	"singleRadio",
  ]

  initialize() {
	this.toggle()
  }

  toggle() {
	if (this.multipleRadioTarget.checked) {
	  this.multipleColumnsContainerTarget.style.display = "block"
	  this.singleColumnContainerTarget.style.display = "none"
	} else if(this.singleRadioTarget.checked) {
	  this.multipleColumnsContainerTarget.style.display = "none"
	  this.singleColumnContainerTarget.style.display = "block"
	} else {
	  this.multipleColumnsContainerTarget.style.display = "none"
	  this.singleColumnContainerTarget.style.display = "none"
	}
  }
}
