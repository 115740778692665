import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [
    "banner",
    "locality",
    "region",
  ]

  presenceComplete(el) {
    return el.value == "" ? false : true
  }
  
  changePresenceComplete(event) {
    this.updatePresenceComplete(event.target)
    this.checkComplete()
  }

  updatePresenceComplete(el) {
    if (this.presenceComplete(el)) {
      el.classList.remove("incomplete")
    } else {
      el.classList.add("incomplete")
    }
  }

  checkComplete() {
    if (
        this.presenceComplete(this.localityTarget) &&
        this.presenceComplete(this.regionTarget)
    ) {
      this.bannerTarget.style.visibility = "hidden"
    } else {
      this.bannerTarget.style.visibility = "visible"
    }
  }

  initialize() {
    [
      this.localityTarget,
      this.regionTarget,
    ].forEach(this.updatePresenceComplete, this)

    this.checkComplete()
  }
}
