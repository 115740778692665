import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
    "unmetJobQualifications",
    "soonToExpireJobQualifications",
    "daysUntil",
    "daysUntilForm",
    "employeeType",
    "employeeShift",
    "workGroup",
    "department",
    "supervisor",
    "establishment",
  ]

  initialize() {
    var establishmentOrDivision = this.daysUntilFormTarget.querySelector("#establishment_or_division").value

    fetch(Routes.unmet_job_qualifications_training_dashboards_path({
      account_id: this.data.get("accountId"),
      employee_type: this.employeeTypeTarget.value,
      employee_shift: this.employeeShiftTarget.value,
      establishment_or_division: establishmentOrDivision,
      work_group: this.workGroupTarget.value,
      department: this.departmentTarget.value,
      supervisor: this.supervisorTarget.value,
    }),
    	  {credentials: "same-origin",
    	   headers: {
    	     "Content-Type": "text/html",
    	     "X-CSRF-Token": getCSRF()}})
      .then(response => response.text())
      .then(data => {
    	this.unmetJobQualificationsTarget.innerHTML = data
      })

    fetch(Routes.soon_to_expire_job_qualifications_training_dashboards_path({
      account_id: this.data.get("accountId"),
      days_until: this.daysUntilTarget.value,
      employee_type: this.employeeTypeTarget.value,
      employee_shift: this.employeeShiftTarget.value,
      establishment_or_division: establishmentOrDivision,
      work_group: this.workGroupTarget.value,
      department: this.departmentTarget.value,
      supervisor: this.supervisorTarget.value,
    }),
  	  {credentials: "same-origin",
  	   headers: {
  	     "Content-Type": "text/html",
  	     "X-CSRF-Token": getCSRF()}})
      .then(response => response.text())
      .then(data => {
  	this.soonToExpireJobQualificationsTarget.innerHTML = data
      })
  }

  updateDaysUntil() {
    this.daysUntilFormTarget.submit()
  }
}
