import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "noneRadio",
    "identifierRadio",
    "singleColumnRadio",
    "bracketIdentifierRadio",
    "identifierContainer",
    "singleColumnContainer",
    "bracketIdentifierContainer",
  ]

  initialize() {
    this.toggle()
  }

  toggle() {
    if (this.identifierRadioTarget.checked) {
      this.identifierContainerTarget.style.display = "block"
      this.singleColumnContainerTarget.style.display = "none"
      this.bracketIdentifierContainerTarget.style.display = "none"
    } else if (this.singleColumnRadioTarget.checked) {
      this.identifierContainerTarget.style.display = "none"
      this.singleColumnContainerTarget.style.display = "block"
      this.bracketIdentifierContainerTarget.style.display = "none"
    } else if (this.bracketIdentifierRadioTarget.checked) {
      this.identifierContainerTarget.style.display = "none"
      this.singleColumnContainerTarget.style.display = "none"
      this.bracketIdentifierContainerTarget.style.display = "block"
    } else {
      this.identifierContainerTarget.style.display = "none"
      this.singleColumnContainerTarget.style.display = "none"
      this.bracketIdentifierContainerTarget.style.display = "none"
    }
  }
}
