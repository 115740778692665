import { Controller } from "stimulus";
import { getCSRF } from "csrf";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = [
    "canvas", // The <canvas> element where the user will draw signatures
    "name", // The <input> element for signer's name
    "captureBtn", // The <button> used to capture the signature and any associated data
  ];

  signaturePad = undefined; // The `SignaturePad` instance wrapping `canvasTarget`

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget, { minDistance: 1 });

    this.signaturePad.onEnd = () => {
      this._updateCaptureBtn();
    };

    this.nameTarget.addEventListener("input", (_) => { 
      this._updateCaptureBtn(); 
    });

    this._updateCaptureBtn();

	// Attach event to jqueryUI lifecycle to clear signature on dialog close
	$(this.element).on('dialogclose', (event, ui) => {
	  this.clear()
	})
  }

  clear(event) {
	if (event) {
      event.preventDefault();
	}
    this.signaturePad.clear();
    this._updateCaptureBtn();
  }

  async capture(event) {
    event.preventDefault();

    const name = this.nameTarget.value;
    const blobPromise = new Promise((resolve) => {
      this.canvasTarget.toBlob((blob) => {
        resolve(blob);
      });
    });
    const blob = await blobPromise;
	
    switch (this.data.get("type")) {
    case "inspection_signature":
      const inspectionSignature = await this._postInspectionSignature(blob, name);
      this.signatureListController.recordSignature(inspectionSignature, name);
      break;
    case "blob":
      const serverBlob = await this._postBlob(blob);
      this.signatureListController.recordSignature(serverBlob, name);
      break;
    }

    this.signaturePad.clear();

	if (event.target.dataset.submitFormId) {
	  document.getElementById(event.target.dataset.submitFormId).submit()
	}
  }

  async _postBlob(blob) {
    const formData = new FormData();
    formData.append("file", blob, "signature.png")

    const route = Routes.api_v1_upload_signature_blob_path()
    const blobResponse = await this._doPost(route, formData);

    if (blobResponse.status < 300) {
      const data = await blobResponse.json();
      return data;
    }
    else {
      throw "Signature not saved";
    }
  }

  async _postInspectionSignature(blob, name) {
    const formData  = new FormData();
    formData.append("inspection_signature[image]", blob, "filename.png");
    formData.append("inspection_signature[name]", name);
    formData.append("inspection_signature[signed_at]", (new Date()).toISOString());

    const route = Routes.api_v1_checklists_inspection_signatures_path({account_id: this.data.get("accountId")});
    const inspectionSignatureResponse = await this._doPost(route, formData);

    if (inspectionSignatureResponse.status < 300) {
      const data = await inspectionSignatureResponse.json();
      return data.inspection_signature;
    }
    else {
      throw "Signature not saved";
    }
  }
 
  _doPost(route, formData) {
    return fetch(route, {
      method: "POST",
      credentials: "same-origin",
      headers: {
        "Authorization": this.data.get("apiToken"),
        "X-CSRF-Token": getCSRF()
      },
      body: formData
    });
  }

  _updateCaptureBtn() {
    const signatureOk = !this.signaturePad.isEmpty();
    const nameOk = !this.hasNameTarget || !!this.nameTarget.value

    if (signatureOk && nameOk) {
      this.captureBtnTarget.removeAttribute("disabled");
    }
    else {
      this.captureBtnTarget.setAttribute("disabled", "disabled");
    }
  }

  get signatureListController() {
    const element = document.getElementById(this.data.get("list"));
    return this.application.getControllerForElementAndIdentifier(
      element,
      "signature-list"
    );
  }
}
