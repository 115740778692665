import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "field",
    "form",
  ]

  clearFields(event) {
    event.preventDefault()
    this.fieldTargets.forEach( el => {
      if (el.type === "checkbox") {
	el.checked = false
      } else {
	el.value = ""
      }
    })
    
    if (this.hasFormTarget) {
      this.formTarget.submit()
    } else {
      this.element.closest("form").submit()
    }
  }
}
