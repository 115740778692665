import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []
  
  initialize() {
	let lat = this.element.dataset.latitude
	let lng = this.element.dataset.longitude
	let apiKey = this.element.dataset.apiKey
	let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
	fetch(url)
	  .then( response => {
		if (response.ok) {
		  return response.json()
		} else {
		  throw new Error("Unable to geocode")
		}
	  })
	  .then( json => {
		this.element.textContent = json["results"][0]["formatted_address"]
	  })
  }
}
