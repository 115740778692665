// Mostly copied from nested_forms_controller however has special behavior for adding.
// Needs to create the new record with the instructor id and remove that instructor from the select
import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
	"noCourseSelected",
	"instructorFields",
	"courseSelect",
	"hiddenCourseId",
	"instructorSelect",
	"template",
	"list",
	"listHeader",
	"courseName",
	"trainingHours",
  ]

  initialize() {
	this.course = null
	this.courseSelectChanged()
  }

  courseSelectChanged(event) {
	var courseId = this.getCourseId()

	if (this.abortCourseController) {
	  this.abortCourseController.abort()
	  this.abortCourseController = null
	}
	
	if (courseId) {
	  this.abortCourseController = new AbortController()
	  fetch(Routes.training_course_path(this.data.get("accountId"), courseId, {format: "json"}), {
		method: "GET",
		credentials: "same-origin",
		headers: {
		  "Content-Type": "application/json",
		  "X-CSRF-Token": getCSRF()},
		signal: this.abortCourseController.signal
	  })
		.then( response => {
		  if (response.ok) {
			return response.json()
		  }
		})
		.then( data => {
		  this.course = data
		  this.courseNameTarget.textContent = this.course.name
		  this.trainingHoursTarget.textContent = this.trainingHoursString()

		  // Boxes are toggled if courseId is null
		  this.noCourseSelectedTarget.classList.add("hidden")
		  this.instructorFieldsTarget.classList.remove("hidden")

		  this.renderInstructorsList()
		})
		.catch( error => {
		  console.log(error)
		})
	} else {
	  this.course = null

	  // Boxes are shown in response to the fetch request for course
	  this.noCourseSelectedTarget.classList.remove("hidden")
	  this.instructorFieldsTarget.classList.add("hidden")
	}
  }
  
  add() {
	var instructorId = this.instructorSelectTarget.value
	var instructor = this.course.instructors.find( instructor => instructor.id == instructorId )
	var content = this.templateTarget.innerHTML
		.replace(/NEW_RECORD/g, new Date().getTime())
		.replace(/INSTRUCTOR_ID/g, instructorId)
		.replace(/INSTRUCTOR_DISPLAY_NAME/g, instructor.display_name)
		.replace(/HOURS/g, this.course.hours)
		.replace(/MINUTES/g, this.course.minutes)
	this.listTarget.insertAdjacentHTML("beforeend", content)

	// Reset the select to empty
	this.instructorSelectTarget.value = ""

	// rerender the instructor select menu
	this.renderInstructorsList()
  }

  remove(event) {
	event.preventDefault()
	let wrapper = event.target.closest(".nested-fields")
	if (wrapper.dataset.newRecord == "true") {
	  wrapper.remove()
	} else {
	  wrapper.querySelector("input[name*='_destroy']").value = 1
	  wrapper.style.display = "none"
	  // Attach a data field to the list item so that renderInstructorList knows that it is not selected
	  wrapper.dataset.removed = 1
	}
	this.renderInstructorsList()
  }

  renderInstructorsList() {
	// Only show instructors who are not already selected
	var existingInstructorIds = Array.from(this.listTarget.querySelectorAll("li:not([data-removed]) .hidden-instructor-id")).map( element => parseInt(element.value) )
	// Clear the instructor select then repopulate
	var newInstructorList = this.course.instructors
		.filter( instructor => !existingInstructorIds.includes(instructor.id) )
		.sort( (a,b) => a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase()))
		.reduce((list, instructor) => {
		  var option = `
<option value="${instructor.id}">
${ instructor.display_name }
</option>
`
		  list = list + option
		  return list
		}, "<option value=''>Choose instructor...</option>")
	this.instructorSelectTarget.innerHTML = newInstructorList
	$(this.instructorSelectTarget).trigger("chosen:updated")

	// add(), remove() and changeCourse all call this, so good place to toggle visiblity of the list header
	if (this.selectedInstructorsCount() > 0) {
	  this.listHeaderTarget.classList.remove("hidden")
	} else {
	  this.listHeaderTarget.classList.add("hidden")
	} 
  }
  
  // For new records, courseId is taken from the select. For existing records which no select
  // the course record should be specified as a hidden target
  getCourseId() {
	if(this.hasCourseSelectTarget) {
	  return this.courseSelectTarget.value
	} else if (this.hasHiddenCourseIdTarget) {
	  return this.hiddenCourseIdTarget.value
	} else {
	  return null
	}
  }

  trainingHoursString() {
	if (this.course) {
	  var hours_str = ""
	  if (this.course.hours == 1) {
		hours_str = "1 hour"
	  } else {
		hours_str = `${this.course.hours} hours`
	  }
	  
	  var minutes_str = ""
	  if (this.course.minutes == 1) {
		minutes_str = "1 minute"
	  } else {
		minutes_str = `${this.course.minutes} minutes`
	  }
	  
	  return `${hours_str} ${minutes_str}`.trim()
	} else {
	  return ""
	}
  }

  selectedInstructorsCount() {
	return this.listTarget.querySelectorAll("li:not([data-removed]").length
  }
}
