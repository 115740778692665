document.addEventListener("turbolinks:load", event => {
  $("main").on("change", ".attachments input[type='file']", event => {
    let submit = $(event.target).next()
    submit.prop("disabled", false)
  })

  $(".attachments .remove-attachment-btn").on("click", event => {
    let $button = $(event.target);
    let $attachmentContainer = $button.closest(".attachments li");
    $attachmentContainer.remove()
  });
})
