export function buildJqueryDatepicker(el) {
  var $el = $(el);
  var datepickerOpts = {changeYear: true,
                        gotoCurrent: true,
                        altField: "[name='" + el.dataset.altSelector + "']",
                        altFormat: "yy-mm-dd",
                        dateFormat: "mm/dd/yy",
                        yearRange: `${el.dataset.startYear}:${el.dataset.endYear}`,
						// Stimulus doesn't pick up jQuery events, so we use our own custom
						// native event when it's necessary for a Stimulus controller wrapping
						// a datepicker to listen and respond to changes.
                        onClose: (newDate, picker) => {
                          document.getElementById(picker.id).dispatchEvent(new Event("oshachange"))
                        }
                       }

  if (el.dataset.changeYear) {
    datepickerOpts.changeYear = el.dataset.changeYear == "true"
  }

  if (el.dataset.maxDate) {
	datepickerOpts.maxDate = el.dataset.maxDate
  }

  $el.datepicker(datepickerOpts);

  var clearButton = $el.next();
  clearButton.on("click", (e) => {
    e.preventDefault();
    e.target.nextElementSibling.value = "";
    $el.datepicker("setDate", null);

    // Send up native change event since can't trust jquery to do it
    el.dispatchEvent(new Event("oshachange"))
  });
}
