import { Controller } from "stimulus"
import { getCSRF } from "csrf"
export default class extends Controller {
  static targets = ["container",
		    "startButton",
		    "stopButton"]
  
  initialize() {
    if (this.hasContainerTarget && this.containerTarget.children.length > 1) {
      $(this.containerTarget).sortable({
	disabled: true,
	stop: (event, ui) => {
	  let sortableIds = []
	  let sortables = this.containerTarget.children
	  for (let i = 0; i < sortables.length; ++i) {
	    sortableIds.push(sortables[i].dataset.sortingId)
	  }
	  fetch(this.data.get("url"), {
	    method: "PATCH",
	    crednetials: "same-origin",
	    headers: {
	      "X-CSRF-Token": getCSRF(),
	      "Content-Type": "application/json"},
	    body: JSON.stringify({sortable_ids: sortableIds})
	  })
	    .catch( error => {
	      console.log("Error trying to sort")
	    })
	}
      })
    } else {
      // Sorting doesn't make sense, hide the sort buttons
      this.hideStartButton()
    }
    this.hideStopButton()
  }
  
  startSorting() {
    this.hideStartButton()
    this.showStopButton()
    $(this.containerTarget).sortable("enable")
  }

  stopSorting() {
    this.hideStopButton()
    this.showStartButton()
    $(this.containerTarget).sortable("disable")
  }

  showStartButton() {
    this.startButtonTarget.classList.remove("hidden")
  }

  hideStartButton() {
    this.startButtonTarget.classList.add("hidden")
  }

  showStopButton() {
    this.stopButtonTarget.classList.remove("hidden")
  }

  hideStopButton() {
    this.stopButtonTarget.classList.add("hidden")
  }
}
