import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "accountId",
    "establishment",
    "incidentType",
    "from",
    "to"
  ]
  
  accountId() {
    return this.accountIdTarget.value
  }
  
  safetyIncidentTags() {
    return Array.from(document.querySelectorAll("[name='safety_incident_tags[]']")).map( function(sel) {
      return sel.value
    }).filter( function(el) {
      return el !== ""
    })
  }

  reportOptions(format) {
    return {
      "from": this.fromTarget.value,
      "to": this.toTarget.value,
      "establishment_or_division": this.establishmentTarget.value,
      "incident_type": this.incidentTypeTarget.value,
      "safety_incident_tags": this.safetyIncidentTags(),
      "format": format,
    }
  }
  
  printReport() {
    location.href = Routes.safety_incidents_summary_reports_path(this.accountId(), this.reportOptions("pdf"))
  }

  downloadIncidentsCsv() {
    location.href = Routes.safety_incidents_summary_reports_path(this.accountId(), this.reportOptions("csv"))
  }

  downloadCorrectiveActionsCsv() {
    location.href = Routes.corrective_actions_safety_incidents_summary_reports_path(this.accountId(), this.reportOptions("csv"))
  }
}
