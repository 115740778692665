// Wraps a button that needs to insert a hidden field into the DOM before submitting

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
	"toInsert",
  ]
  
  submit(event) {
	const toInsert = document.importNode(this.toInsertTarget.content, true)
	const form = event.target.closest("form")
	form.appendChild(toInsert)
	form.submit()
  }
}
