import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "activeColumnContainer",
    "valuesRadio",
    "activePRadio",
    "inactivePRadio",
    "presenceRadio",
    "presenceManagedRadio",
    "valuesContainer",
    "activePContainer",
    "inactivePContainer",
    "presenceContainer",
    "presenceManagedContainer",
  ]

  initialize() {
    this.toggle()
  }

  toggle() {
    if (this.valuesRadioTarget.checked) {
      this.activeColumnContainerTarget.style.display = "flex"
      this.valuesContainerTarget.style.display = "block"
      this.activePContainerTarget.style.display = "none"
      this.inactivePContainerTarget.style.display = "none"
      this.presenceContainerTarget.style.display = "none"
      this.presenceManagedContainerTarget.style.display = "none"
    } else if (this.activePRadioTarget.checked) {
      this.activeColumnContainerTarget.style.display = "flex"
      this.valuesContainerTarget.style.display = "none"
      this.activePContainerTarget.style.display = "block"
      this.inactivePContainerTarget.style.display = "none"
      this.presenceContainerTarget.style.display = "none"
      this.presenceManagedContainerTarget.style.display = "none"
    } else if (this.inactivePRadioTarget.checked) {
      this.activeColumnContainerTarget.style.display = "flex"
      this.valuesContainerTarget.style.display = "none"
      this.activePContainerTarget.style.display = "none"
      this.inactivePContainerTarget.style.display = "block"
      this.presenceContainerTarget.style.display = "none"
      this.presenceManagedContainerTarget.style.display = "none"
    } else if (this.presenceRadioTarget.checked) {
      this.activeColumnContainerTarget.style.display = "none"
      this.valuesContainerTarget.style.display = "none"
      this.activePContainerTarget.style.display = "none"
      this.inactivePContainerTarget.style.display = "none"
      this.presenceContainerTarget.style.display = "block"
      this.presenceManagedContainerTarget.style.display = "none"
    } else if (this.presenceManagedRadioTarget.checked) {
      this.activeColumnContainerTarget.style.display = "none"
      this.valuesContainerTarget.style.display = "none"
      this.activePContainerTarget.style.display = "none"
      this.inactivePContainerTarget.style.display = "none"
      this.presenceContainerTarget.style.display = "none"
      this.presenceManagedContainerTarget.style.display = "block"
    }
  }
}
