export function buildChosen(select) {
  var opts = {
    disable_search_threshold: 10,
    search_contains: true,
    inherit_select_classes: true
  };

  if (select.dataset.width) {
    opts.width = select.dataset.width;
  }

  if (select.dataset.disableSearchThreshold) {
    opts.disable_search_threshold = select.dataset.disableSearchThreshold;
  }

  if (select.dataset.disableSearch) {
    opts.disable_search = true;
  }

  if (select.dataset.inheritSelectClasses) {
    opts.inherit_select_classes = true
  }

  let $select = $(select)
  // Confirm that this select has not already been processed by chosen. This prevents
  // the back button trigging the insertion of an additional select
  if (!$select.next().hasClass("chosen-container")) {
    $select.chosen(opts);
    $select.chosen().change( () => {
      /*
        Chosen emits change and input events via jquery. However Stimulus only responds to
        browser native events sent via dispatchEvent. Emiting the custom oshachange event
        allows us to attach a Stimulus action to when the chosen value changes. We cannot
	just emit the change event because Chosen is listening to the <select> change
	event and doing so will retrigger this event handler causing an infinite loop.
      */
      select.dispatchEvent(new Event("oshachange"))
    })
  }
}
