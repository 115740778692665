import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "accountId",
    "from",
    "to",
    "establishment",
    "incidentType",
  ]

  initialize() {
    document.querySelectorAll("canvas").forEach( el => {
      var rawData = JSON.parse(el.dataset.graphData)
      var ctx = el.getContext("2d")
      var labels = Object.getOwnPropertyNames(rawData)
      var values = labels.map( function(label) {
	return rawData[label][0]
      })

      if (labels.length > 0) {
	var chart = new Chart(ctx, {
	  type: "pie",
	  data: {
	    datasets: [{
	      data: values,
	      backgroundColor: this.chartColors()
	    }],
	    labels: labels
	  },
	  options: {
	    legend: {
	      display: false
	    },
	    animation: {
	      duration: 0
	    }
	  }
	});
      } else {
	var w = ctx.canvas.width;
	var h = ctx.canvas.height;
	var lineWidth = 4;
	var r = (Math.min(w, h) / 2) - lineWidth;
	ctx.beginPath();
	ctx.lineWidth = lineWidth;
	ctx.arc(w / 2, h / 2, r, 0, 2 * Math.PI);
	ctx.stroke();

	ctx.font ="20px sans-serif";
	ctx.textAlign = "center";
	ctx.fillText("No Data", w / 2, h / 2);
      }
    })
  }

  accountId() {
    return this.accountIdTarget.value
  }
  
  chartColors() {
    let colors = [
      "#5DA5DA",
      "#FAA43A",
      "#60BD68",
      "#F17CB0",
      "#B2912F",
      "#B276B2",
      "#DECF3F",
      "#F15854",
      "#4D4D4D",
    ];
    // Create a repeated list of these colors for when there are too many options
    colors = colors.concat(colors)
    colors = colors.concat(colors)

    return colors
  }

  printReport() {
    location.href = Routes.incident_tag_breakdown_reports_path(this.accountId(), {
      from: this.fromTarget.value,
      to: this.toTarget.value,
      establishment_or_division: this.establishmentTarget.value,
      incident_type: this.incidentTypeTarget.value,
      format: "pdf",
    })
  }
}
