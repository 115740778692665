import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field",
		    "checkbox"]

  connect() {
    // If value is the sentinal 9999, start with marked as not expiring
    if (this.fieldTarget.value === "9999") {
      this.fieldTarget.disabled = true
      this.fieldTarget.style.visibility = "hidden"
      this.checkboxTarget.checked = true
    }
  }
  
  toggle() {
    if (this.checkboxTarget.checked) {
      // Hide the field so that the default 9999 value will come through.
      this.fieldTarget.disabled = true
      this.fieldTarget.style.visibility = "hidden"
    } else {
      this.fieldTarget.disabled = false
      this.fieldTarget.style.visibility = "visible"
      this.fieldTarget.value = ""
    }
  }
}
  
