import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
	"pedestrianInvolvedYes",
	"pedestrianInvolvedNo",
	"pedestrianActionContainer"
  ]

  initialize() {
	this.togglePedestrianInvolved()
  }

  togglePedestrianInvolved() {
	this.pedestrianActionContainerTarget.style.display = this.pedestrianInvolvedYesTarget.checked ? "block" : "none"
  }
}
