import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "inactivityLogoutCheckbox",
    "inactivityUntilLogoutLabel",
    "inactivityUntilLogoutInput",
	"lockoutCheckbox",
    "lockoutAttemptsLabel",
    "lockoutAttemptsInput",
    "lockoutDurationLabel",
    "lockoutDurationInput"
  ];

  connect() {
    const inactivityLogoutChecked = this.inactivityLogoutCheckboxTarget.checked;
    this._updateInactivityLogoutFields(inactivityLogoutChecked);

	const checked = this.lockoutCheckboxTarget.checked;
    this._updateLockoutFields(checked);
  }

  toggleInactivityLogout(event) {
    const checked = event.target.checked;
    this._updateInactivityLogoutFields(checked);
  }

  toggleLockout(event) {
	const checked = event.target.checked;
    this._updateLockoutFields(checked);
  }

  _updateInactivityLogoutFields(checked) {
    if (checked) {
      this._enable(this.inactivityUntilLogoutLabelTarget);
      this._enable(this.inactivityUntilLogoutInputTarget);
    } else {
      this._disable(this.inactivityUntilLogoutLabelTarget);
      this._disable(this.inactivityUntilLogoutInputTarget);
	}
  }

  _updateLockoutFields(checked) {
    if (checked) {
      this._enable(this.lockoutAttemptsLabelTarget);
      this._enable(this.lockoutAttemptsInputTarget);
      this._enable(this.lockoutDurationLabelTarget);
      this._enable(this.lockoutDurationInputTarget);
    } else {
      this._disable(this.lockoutAttemptsLabelTarget);
      this._disable(this.lockoutAttemptsInputTarget);
      this._disable(this.lockoutDurationLabelTarget);
      this._disable(this.lockoutDurationInputTarget);

    }
  }

  _disable(element) {
    element.setAttribute("disabled", "disabled");
    element.classList.add("disabled");
    
  }

  _enable(element) {
    element.removeAttribute("disabled");
    element.classList.remove("disabled");
  }
}
