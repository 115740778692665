import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
	"soilContaminationYes",
	"soilContaminationNo",
	"waterContaminationYes",
	"waterContaminationNo",
	"additionalAttributesContainer"
  ]

  initialize() {
	this.toggleWaterContamination()
  }

  toggleWaterContamination() {
	this.additionalAttributesContainerTarget.style.display = this.waterContaminationYesTarget.checked ? "block" : "none"
  }
}
