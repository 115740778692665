import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field"]

  clearField(event) {
    event.preventDefault()
    this.fieldTarget.value = ""
    this.element.closest("form").submit()
  }
}
