import { Controller } from "stimulus"

// Has built-in dynamic submit
// Searching by checklist or checklist type are orthogonal operations. Searching by
// one should clear the other, and vice versa.

export default class extends Controller {
  static targets = [
    "checklist",
    "checklistType",
    "checklistData",
  ]

  changeChecklist(event) {
    if (this.hasChecklistTypeTarget) {
      this.checklistTypeTarget.value = ""
    }
    event.target.closest("form").submit()
  }

  changeChecklistType(event) {
    this.checklistTarget.value = ""
    event.target.closest("form").submit()
  }
}
