import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let title = this.data.get("title") || "Leading Indicator"
    let injuryData = JSON.parse(this.data.get("injuries"))
    let ttrData = JSON.parse(this.data.get("comparison"))
    let xLabels = injuryData.map( e => e[0] )
    let injuryValues = injuryData.map( e => e[1] )
    let ttrValues = ttrData.map( e => { return e[1] === 0 ? null : e[1] } )
    
    var ctx = this.element.getContext("2d")

    var chart = new Chart(ctx, {
      type: "bar",
      data: {
		labels: xLabels,
		datasets: [{
		  data: ttrValues,
		  label: this.data.get("comparison-label"),
		  backgroundColor: "#368bee",
		  pointRadius: 6,
		  borderColor: "#3c36ed",
		  borderWidth: 4,
		  type: "line",
		  yAxisID: "y-axis-comparison",
		  fill: false,
		  spanGaps: true
		}, {
		  data: injuryValues,
		  label: "Injuries per Month",
		  backgroundColor: "#f87300",
		  borderColor: "#9b500d",
		  borderWidth: 2,
		  yAxisID: "y-axis-injuries",
		}]
      },
      options: {
		title: {
		  display: true,
		  text: title,
		  fontSize: 16,
		  fontColor: "#333"
		},
		scales: {
		  yAxes: [{
			type: "linear",
			display: true,
			position: "right",
			id: "y-axis-injuries",
			gridLines: {
			  drawOnChartArea: false
			},
			scaleLabel: {
			  display: true,
			  labelString: "Injuries per Month"
			},
			ticks: {
			  min: 0,
			},
		  }, {
			type: "linear",
			dipslay: true,
			position: "left",
			id: "y-axis-comparison",
			gridLines: {
			  drawOnChartArea: false
			},
			scaleLabel: {
			  display: true,
			  labelString: this.data.get("comparison-units")
			},
			ticks: {
			  min: 0,
			  suggestedMax: this.data.get("suggested-max")
			}
		  }]
		},
		animation: {
		  duration: 0
		}
      }
    })
  }
}
