import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "contentDisplay",
  ]

  initialize() {
    document.querySelector(this.contentSelector()).addEventListener("input", event => {
      this.contentChanged(event)
    })
    this.contentChanged()
  }
  
  contentSelector() {
    return `[name="${this.element.dataset.contentName}"]`
  }
  
  content() {
    return document.querySelector(this.contentSelector()).value
  }
  
  contentChanged(event) {
    if(this.content() == "") {
      this.contentDisplayTarget.classList.remove("has-content")
    } else {
      this.contentDisplayTarget.classList.add("has-content")
    }
  }
}
