// Loading ujs from sprockets
//require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "controllers"

// Run for side effects
import "onload"
import "direct_upload"
import "attachments"
import "flash"


