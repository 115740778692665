import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["addButton",
					"optionsAndHeader",
					"optionsContainer",
					"newOptionText",
					"formValue",
					"optionTemplate"]

  options = []
  
  initialize() {
	this.options = JSON.parse(this.formValueTarget.value).sort()
	this.sync()
  }
  
  add(e) {
	e.preventDefault()
	let newOption = this.newOptionTextTarget.value
	if (newOption !== "") {
	  this.options.push(newOption)
	  this.options = this.options.sort()
	}
	this.newOptionTextTarget.value = ""
	this.sync()
  }

  remove(e) {
	e.preventDefault()
	let option = e.target.parentElement.querySelector("p").textContent
	this.options = this.options.filter( opt => opt !== option )
	this.sync()
  }

  optionUpdated() {
	this.sync()
  }

  sync() {
	this.addButtonTarget.disabled = (this.newOptionTextTarget.value === "")

	// Hide the container if it is empty
	if (this.options.length === 0) {
	  this.optionsAndHeaderTarget.classList.add("hidden")
	} else {
	  this.optionsAndHeaderTarget.classList.remove("hidden")
	}
	
	// Render the options to the container
	this.optionsContainerTarget.innerHTML = ""
	let t = this.optionTemplateTarget.content
	this.options.forEach( option => {
	  let li = t.cloneNode(true)
	  li.querySelector("p").textContent = option
	  this.optionsContainerTarget.appendChild(li)
	})

	this.formValueTarget.value = JSON.stringify(this.options)
  }
}
