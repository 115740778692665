import { Controller } from "stimulus"
import { radioValue } from "radio_value"

export default class extends Controller {
  static targets = [
	"action",
    "followupDateContainer",
    "endedContainer",
    "followupDate",
    "endedDate",
  ]

  sync() {
    // Always blank out the values of both dates on any change, force
    // the user to specify a date
    $(this.followupDateTarget).datepicker("setDate", null)
    $(this.endedDateTarget).datepicker("setDate", null)

    switch(radioValue(this.actionTargets)) {
    case "end":
      this.followupDateContainerTarget.style.display = "none"
      this.endedContainerTarget.style.display = "block"
      break;
    case "new-followup":
      this.followupDateContainerTarget.style.display = "block"
      this.endedContainerTarget.style.display = "none"
      break;
    case "leave-open":
      this.followupDateContainerTarget.style.display = "none"
      this.endedContainerTarget.style.display = "none"
      break;
    default:
	  debugger
      this.followupDateContainerTarget.style.display = "none"
      this.endedContainerTarget.style.display = "none"
    }
  }

  initialize() {
    this.sync()
  }
}
