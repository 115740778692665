import { DirectUpload } from "@rails/activestorage";

// http://edgeguides.rubyonrails.org/active_storage_overview.html

document.addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
})

document.addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

document.addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

document.addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

document.addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

function uploadFile(file, url, fileInput) {
  const upload = new DirectUpload(file, url)

  upload.create((error, blob) => {
    if (error) {
      // TODO: handle error
    } else {
      var form = $(fileInput).closest("form")
      const hiddenField = document.createElement("input")
      hiddenField.setAttribute("type", "hidden")
      hiddenField.setAttribute("value", blob.signed_id)
      hiddenField.name = fileInput.name
      form.append(hiddenField)

      // build a file picker widget by hand, because why not?
      var li = document.createElement("li")

      var p = document.createElement("p")
      p.appendChild(document.createTextNode(blob.filename))
      li.appendChild(p)

      var img = document.createElement("img")
      // Only do the source if it's actually an image, else use our default file image
      if (blob.content_type.match(/^image/) && blob.content_type != "image/svg+xml") {
        img.setAttribute("src", Routes.rails_service_blob_path({signed_id: blob.signed_id, filename: blob.filename}))
      } else {
        img.setAttribute("src", "/generic_file.svg")
      }
      img.classList.add("force-resize")
      li.appendChild(img)

      var removeButton = document.createElement("button")
      removeButton.classList.add("link")
      removeButton.classList.add("alert")
      removeButton.setAttribute("type", "button")
      removeButton.appendChild(document.createTextNode("Remove"))
      removeButton.addEventListener("click", event => {
        hiddenField.remove()
        li.remove()
      })
      li.appendChild(removeButton)

      // possible there were multiple file picker widgets in the form. Make
      // sure we find the widget relative to the fileInput
      var attachmentContainer = fileInput.closest(".attachments").querySelector("ul")
      attachmentContainer.appendChild(li)
    }
  })
}

function uploadAnnotatedFile(file, url, fileInput) {
  const upload = new DirectUpload(file, url)

  upload.create((error, blob) => {
    if (error) {
      // TODO: handle error
    } else {
      var form = $(fileInput).closest("form")
      
      // Attach blob signed_id to the form so the file can be added
      const hiddenField = document.createElement("input")
      hiddenField.setAttribute("type", "hidden")
      hiddenField.setAttribute("value", blob.signed_id)
      hiddenField.name = fileInput.name
      form.append(hiddenField)
      
      // Build the preview menu item of the attached file
      var li = document.createElement("li")

      var p = document.createElement("p")
      p.appendChild(document.createTextNode(blob.filename))
      li.appendChild(p)

      var img = document.createElement("img")
      // Only do the source if it's actually an image, else use our default file image
      if (blob.content_type.match(/^image/) && blob.content_type != "image/svg+xml") {
        img.setAttribute("src", Routes.rails_service_blob_path({signed_id: blob.signed_id, filename: blob.filename}))
      } else {
        img.setAttribute("src", "/generic_file.svg")
      }
      img.classList.add("force-resize")
      li.appendChild(img)

      var linkRow = document.createElement("div")
      linkRow.classList.add("link-row")
      linkRow.dataset.controller = "has-content"
      linkRow.dataset.contentName = fileInput.getAttribute("name").replace("[attachments][]", `[attachment_notes][${blob.signed_id}]`)
      li.appendChild(linkRow)

      var removeButton = document.createElement("button")
      removeButton.classList.add("link")
      removeButton.classList.add("alert")
      removeButton.setAttribute("type", "button")
      removeButton.appendChild(document.createTextNode("Remove"))
      removeButton.addEventListener("click", event => {
        hiddenField.remove()
        li.remove()
      })
      linkRow.appendChild(removeButton)

      // Build the notes dialog
      var template = document.getElementById("image-notes-dialog")
      linkRow.appendChild(template.content.cloneNode(true))
      var dialog = linkRow.querySelector(".notes-dialog")
      var $dialog = $(dialog)
      var textarea = dialog.querySelector("textarea")
      textarea.setAttribute("name", fileInput.getAttribute("name").replace("[attachments][]", `[attachment_notes][${blob.signed_id}]`))
      textarea.setAttribute("form", form.attr("id"))
      dialog.querySelector(".close").addEventListener("click", function(event) {
	event.preventDefault()
	$dialog.dialog("close")
      })

      $dialog.dialog({
	autoOpen: false,
	draggable: false,
	modal: true,
	closeText: "X",
	width: 600,
      })
      
      var notesButton = document.createElement("button")
      notesButton.classList.add("link")
      notesButton.dataset.annotatedDialog = "true"
      notesButton.dataset.target = "has-content.contentDisplay"
      notesButton.setAttribute("type", "button")
      notesButton.appendChild(document.createTextNode("Notes"))
      notesButton.dataset.filename = blob.filename
      notesButton.addEventListener("click", event => {
      })
      linkRow.appendChild(notesButton)

      notesButton.addEventListener( "click", function(event) {
	event.preventDefault()
	$dialog.dialog("open")
      })
      
      // Possible there were multiple file picker widgets in the form. Make
      // sure we find the widget relative to the fileInput
      var attachmentContainer = fileInput.closest(".attachments").querySelector("ul")
      attachmentContainer.appendChild(li)
    }
  })
}

// Upload file previews, used for the file input change event on a new record form
export function previewUpload(el) {
  el.addEventListener("change", event => {
    Array.from(event.target.files).forEach(file => uploadFile(file, event.target.dataset.directUploadUrl, el))
    event.target.value = null
  })
}

export function previewAnnotatedUpload(el) {
  el.addEventListener("change", event => {
    Array.from(event.target.files).forEach(file => uploadAnnotatedFile(file, event.target.dataset.directUploadUrl, el))
    event.target.value = null
  })
}

export function buildAnnotatedDialog(el) {
  var $el = $(el)
  var $dialog = $(el.nextElementSibling)

  $el.click( function(event) {
    event.preventDefault()
    $dialog.dialog("open")
  })
	     
  $dialog.dialog({
    autoOpen: false,
    draggable: false,
    modal: true,
    closeText: "X",
    width: 600,
  })

  $dialog.on("click", ".close", function(vent) {
    event.preventDefault()
    $dialog.dialog("close")
  })
}
