import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
	"newValueField",
	"values",
	"initialValues",
	"paramName",
  ]

  initialize() {
	// Listen to enter key on newValueField input for submit
	this.newValueFieldTarget.addEventListener("keydown", (event) => {
	  if(event.code == "Enter") {
		this.addValue(event)
	  }
	})
	
	this.values = new Set(JSON.parse(this.initialValuesTarget.value))
	this.renderValues()
  }
  
  addValue(event) {
	event.preventDefault()
	const newValue = this.newValueFieldTarget.value
	if (newValue) {
	  this.values.add(newValue)
	  this.newValueFieldTarget.value = ""
	  this.renderValues()
	}
  }

  removeValue(event) {
	event.preventDefault()
	const li = event.target.closest("li")
	const value = li.querySelector(".value").textContent
	if (this.values.has(value)) {
	  this.values.delete(value)
	  this.renderValues()
	}
  }

  renderValues() {
	const valuesFragment = Array.from(this.values).sort().reduce( (list, value) => {
	  const li = document.createElement("li")
	  li.classList.add("string-array-item")

	  const valueEl = document.createElement("p")
	  valueEl.textContent = value
	  valueEl.classList.add("value")
	  li.appendChild(valueEl)

	  const valueInput = document.createElement("input")
	  valueInput.type = "hidden"
	  valueInput.value = value
	  valueInput.name = `hr_integration[${this.paramNameTarget.value}][]`
	  li.appendChild(valueInput)

	  const removeButton = document.createElement("button")
	  removeButton.type = "button"
	  removeButton.textContent = "Remove"
	  removeButton.dataset.action = "string-array#removeValue"
	  removeButton.classList.add("link")
	  li.appendChild(removeButton)

	  list.appendChild(li)
	  return list
	}, document.createDocumentFragment())
	this.valuesTarget.innerHTML = ""
	this.valuesTarget.appendChild(valuesFragment)
  }
}
