import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "signatureData", "listHost"];

  // Aspect ratio should be consistent with signatures that are generated elsewhere.
  HEIGHT = 75;
  WIDTH = 200;

  connect() {
    // So that the list of signatures gets submitted with the form that contains this controller, even if the list is empty:
	// Insert hiddenInput BEFORE the listHost to make sure that the listHost values are submitted and not the blank from
	// the hidden element.
    const hiddenInput = this._buildHiddenInput();
	this.listHostTarget.parentNode.insertBefore(hiddenInput, this.listHostTarget)

    this.signatureDataTargets.forEach((div) => {
      const signatureContainer = this._buildSignatureContainer(
        div.dataset.id,
        div.dataset.name,
        div.dataset.imgSrc
      );

	  const signaturesContainer = this.element.querySelector(".signature-list") || this.element
      signaturesContainer.appendChild(signatureContainer);
      div.remove();
    });
  }
  
  removeSignature(event) {
    event.preventDefault();

    const button = event.target;
    const signatureId = button.dataset.signatureId;
    const signatureContainer = this.itemTargets.find(
      (x) => x.dataset.signatureId === signatureId
    );

    signatureContainer.remove();

	if (this.element.dataset.submitOnDelete === "true") {
 	  this.element.submit()
	}
  }

  // This is for other controllers to call, not for DOM elements to use as a Stimulus action
  recordSignature(resource, name) {
    switch (this.data.get('type')) {
    case "inspection_signature":
      this.listHostTarget.appendChild(
        this._buildSignatureContainer(
          resource.id,
          name,
          resource.image_url,
        )
      );
      break;
      
    case "blob":
      if (this.hasItemTarget) {
        this.itemTarget.remove();
      }
	  
      this.listHostTarget.appendChild(
        this._buildSignatureContainer(
          resource.signed_id,
          name,
          resource.url,
        )
      );
      break;
    }
  }

  _buildSignatureContainer(id, name, imgSrc) {
    const container = document.createElement("div");
    container.dataset["target"] = "signature-list.item";
    container.dataset["signatureId"] = id;
    switch (this.data.get("type")) {
      case "blob":
        container.classList.add("p-2", "w-full");
        break;
      case "inspection_signature":
        container.classList.add("mb-4", "p-2", "w-4/12");
        break;
    }

    const img = document.createElement("img");
    img.src = imgSrc;
    img.height = this.HEIGHT;
    img.width = this.WIDTH;
    switch (this.data.get("type")) {
      case "blob":
        img.classList.add("block", "mx-auto");
        break;
      case "inspection_signature":
        img.classList.add("block", "mb-4", "mt-4", "mx-auto");
        break;
    }
    container.appendChild(img);

    const idInput = document.createElement("input");
    idInput.type = "hidden";
    idInput.name = this.data.get("idInputName");
    idInput.value = id;
    container.appendChild(idInput);

    if (this.data.get("nameInputName")) {
      const nameInput = document.createElement("input");
      nameInput.type = "hidden";
      nameInput.name = this.data.get("nameInputName");
      nameInput.value = name;
      container.appendChild(nameInput);
    }

    const labelContainer = document.createElement("div");
    labelContainer.classList.add("label-container", "flex", "justify-between", "p-2");
    container.appendChild(labelContainer);

    const label = document.createElement("label");
    label.classList.add("signature-label");
    label.innerText = name;
    labelContainer.appendChild(label);

    const button = document.createElement("button");
    button.type = "button";
    button.dataset["action"] = "signature-list#removeSignature";
    button.dataset["signatureId"] = id;
    button.classList.add("alert", "link");
    button.innerText = "Delete";
	button.dataset.confirm = `Are you sure you want to delete this signature for ${name}?`
    labelContainer.appendChild(button);

    return container;
  }

  _buildHiddenInput() {
    const idInput = document.createElement("input");
    idInput.type = "hidden";
    idInput.name = this.data.get("idInputName");
    idInput.value = "";
    return idInput;
  }
}
