import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "credentialsMethodPassword",
    "credentialsMethodSSH",
    "passwordSettings",
    "sshSettings",
  ]

  initialize() {
    this.sync()
  }

  sync() {
    if (this.credentialsMethodPasswordTarget.checked) {
      this.passwordSettingsTarget.classList.remove("hidden")
      this.sshSettingsTarget.classList.add("hidden")
    } else if (this.credentialsMethodSSHTarget.checked) {
      this.passwordSettingsTarget.classList.add("hidden")
      this.sshSettingsTarget.classList.remove("hidden")
    } else {
      this.passwordSettingsTarget.classList.add("hidden")
      this.sshSettingsTarget.classList.add("hidden")
    }
  }
}
