import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "select",
  ]

  selectAll() {
    [...this.selectTarget.querySelectorAll("option")].forEach( opt => {
      opt.selected = true
    })
    $(this.selectTarget).trigger("chosen:updated")
  }
}
