import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "accountId",
    "establishment",
    "from",
    "to",
  ]

  accountId() {
    return this.accountIdTarget.value
  }

  safetyIncidentTags() {
    return Array.from(document.querySelectorAll("[name='safety_incident_tags[]']")).map( function(sel) {
      return sel.value
    }).filter( function(el) {
      return el !== ""
    })
  }

  printReport() {
    location.href = Routes.safety_activity_summary_reports_path(this.accountId(), {
      "from": this.fromTarget.value,
      "to": this.toTarget.value,
      "establishment_or_division": this.establishmentTarget.value,
      "safety_incident_tags": this.safetyIncidentTags(),
      format: "pdf",
    })
  }
}
