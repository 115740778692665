import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = ["summaryContainer"]

  change(event) {
    if (event.target.value) {
      fetch(Routes.nameless_summary_medical_facility_path(event.target.value,{account_id: this.element.dataset.accountId}), {credentials: "same-origin",
                                                                                                                             headers: {
                                                                                                                               "X-CSRF-Token": getCSRF()}})
	.then(response => response.text())
	.then(html => {
	  this.summaryContainerTarget.style.display = "block"
          this.summaryContainerTarget.innerHTML = html
	})
    } else {
      this.summaryContainerTarget.style.display = "none"
    }
  }
}
