import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "accountId",
    "year",
  ]
  
  accountId() {
    return this.accountIdTarget.value
  }

  year() {
    return this.yearTarget.value
  }
  
  log300all() {
    location.href = Routes.all_300_forms_osha_summary_forms_path(this.accountId(), {
      "year": this.year(),
    })
  }
  
  log300Aall() {
    location.href = Routes.all_300a_forms_osha_summary_forms_path(this.accountId(), {
      "year": this.year(),
    })
  }

  csv300301all() {
    location.href = Routes.all_300301_csv_osha_injury_tracking_reports_path(this.accountId(), {
      "year": this.year(),
    })
  }
}
