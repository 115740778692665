import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
	"fileKindSelect",
	"xlsxFile",
	"csvFile",
  ]

  initialize() {
	this.sync()
  }

  fileKindChanged() {
	this.sync()
  }
  
  sync() {
	switch(this.fileKindSelectTarget.value) {
	case "xlsx":
	  this.xlsxFileTarget.classList.remove("hidden")
	  this.csvFileTarget.classList.add("hidden")
	  break;
	case "csv":
	  this.xlsxFileTarget.classList.add("hidden")
	  this.csvFileTarget.classList.remove("hidden")
	  break;
	default:
	  this.xlsxFileTarget.classList.add("hidden")
	  this.csvFileTarget.classList.add("hidden")
	  break;
	}
  }
}
