import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "banner",
    "name",
    "streetAddress",
    "locality",
    "region",
    "postalCode",
    "naicsCode",
    "industryDescription",
    "reportingYearAverageEmployees",
    "reportingYearHoursWorked",
    "reportingYearOrganizationSize",
  ]

  presenceComplete(el) {
    return el.value == "" ? false : true
  }

  numberComplete(el) {
    let number = parseInt(el.value)
    return !isNaN(number) && (number > 0)
  }
  
  radioPresenceComplete(els) {
    return els.map( el => el.checked ).some( c => c )
  }
  
  changePresenceComplete(event) {
    this.updatePresenceComplete(event.target)
    this.checkComplete()
  }

  changeNumberComplete(event) {
    this.updateNumberComplete(event.target)
    this.checkComplete()
  }

  changeRadioPresenceComplete(event) {
    let radios = Array.from(document.getElementsByName(event.target.name))
    this.updateRadioPresenceComplete(radios)
    this.checkComplete()
  }

  updatePresenceComplete(el) {
    if (this.presenceComplete(el)) {
      el.classList.remove("incomplete")
    } else {
      el.classList.add("incomplete")
    }
  }

  updateNumberComplete(el) {
    if (this.numberComplete(el)) {
      el.classList.remove("incomplete")
    } else {
      el.classList.add("incomplete")
    }
  }

  updateRadioPresenceComplete(els) {
    if (this.radioPresenceComplete(els)) {
      els.forEach( el => el.classList.remove("incomplete"))
    } else {
      els.forEach( el => el.classList.add("incomplete"))
    }
  }

  checkComplete() {
    if (this.presenceComplete(this.nameTarget) &&
        this.presenceComplete(this.streetAddressTarget) &&
        this.presenceComplete(this.localityTarget) &&
        this.presenceComplete(this.regionTarget) &&
        this.presenceComplete(this.postalCodeTarget) &&
        this.presenceComplete(this.naicsCodeTarget) &&
	this.numberComplete(this.reportingYearAverageEmployeesTarget) &&
	this.numberComplete(this.reportingYearHoursWorkedTarget) &&
	this.presenceComplete(this.reportingYearOrganizationSizeTarget) &&
        this.presenceComplete(this.industryDescriptionTarget)) {
      this.bannerTarget.style.visibility = "hidden"
    } else {
      this.bannerTarget.style.visibility = "visible"
    }
  }

  initialize() {
    [
      this.nameTarget,
      this.streetAddressTarget,
      this.localityTarget,
      this.regionTarget,
      this.postalCodeTarget,
      this.naicsCodeTarget,
      this.industryDescriptionTarget,
      this.reportingYearOrganizationSizeTarget,
    ].forEach(this.updatePresenceComplete, this)

    this.updateNumberComplete(this.reportingYearAverageEmployeesTarget)
    this.updateNumberComplete(this.reportingYearHoursWorkedTarget)
    
    this.checkComplete()
  }
}
