import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["date", "hour", "minute", "hiddenInput"];

  connect() {
    this.updateDatetime(undefined);
  }

  updateDatetime(_event) {
    const dt = this._getCurrentDatetime();
    this.hiddenInputTarget.value = dt ? dt.toISOString() : "";
  }

  _getCurrentDatetime() {
    const date = String(this.dateTarget.value);
    if (date === "") {
      return undefined;
    }

    const hour = parseInt(String(this.hourTarget.value));
    const minute = parseInt(String(this.minuteTarget.value));
    if (isNaN(hour) || isNaN(minute)) {
      return undefined;
    }

    const [monthStr, dateStr, yearStr] = date.split("/");

    return new Date(
      parseInt(yearStr),
      parseInt(monthStr) - 1,
      parseInt(dateStr),
      hour,
      minute,
    );
  }
}
