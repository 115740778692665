import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "checklists",
    "checklistTypes",
  ]
  
  filterChecklists() {
    var checklistData = JSON.parse(this.checklistTypesTarget.dataset.rawData)

    // Rebuild the checklists select

    var blankOption = document.createElement("option")
    blankOption.textContent = "Choose a checklist..."
    this.checklistsTarget.innerHTML = ""
    this.checklistsTarget.append(blankOption)

    if (this.checklistTypesTarget.value != "") {
      // filter checklist data based on types
      checklistData = checklistData.filter( cl => {
	return cl.checklist_type_id == this.checklistTypesTarget.value
      })
    }
    
    checklistData.forEach( cl => {
      var id = cl.id
      var name = cl.name
      var option = document.createElement("option")
      option.value = id
      option.textContent = name
      this.checklistsTarget.append(option)
    })
    $(this.checklistsTarget).trigger("chosen:updated")
  }
}

