import { buildJqueryDatepicker } from "datepickers"
import { buildChosen } from "chosen"

export function buildDialog(el) {
  var $el = $(el)
  var $dialog = $("#" + el.dataset.dialog)

  // Copy the form component of the dialog, then create a template of it for future refreshes
  var theForm = $dialog.find("form")
  if (theForm.length > 0) {
    var formTemplate = document.createDocumentFragment()
    formTemplate.appendChild(theForm.get(0).cloneNode(true))
  }

  $el.click( function(event) {
    event.preventDefault()
    $dialog.dialog("open")
  })

  $dialog.dialog({
    autoOpen: false,
    draggable: false,
    modal: true,
    closeText: "X",
    width: 600,
    close: function(event, ui) {
      // replace the form with the template
      // sometimes a dialog does not have a form, in which case this is unnecessary
      if (formTemplate !== undefined) {
        $dialog.find("form").replaceWith(formTemplate.cloneNode(true))

        // rerun the datepicker code
        $dialog.find("form")[0].querySelectorAll(".datepicker").forEach( buildJqueryDatepicker )

        // rerun chosen code
        $dialog[0].querySelectorAll("select:not(.skip-chosen)").forEach(buildChosen)
      }

      // Clear any attributes flaged with data-dialog-clear="true"
      $dialog.find("[data-dialog-clear]").each( (index, el) => {
	el.value = ""
      })
    }
  })

  $dialog.on("click", ".close", function(e) {
    e.preventDefault()
    $dialog.dialog("close")
  })
}
