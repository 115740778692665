import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["banner",
                    "name",
                    "streetAddress",
                    "locality",
                    "region",
                    "postalCode"]

    presenceComplete(el) {
    return el.value == "" ? false : true
  }

  changePresenceComplete(event) {
    this.updatePresenceComplete(event.target)
    this.checkComplete()
  }

  updatePresenceComplete(el) {
    if (this.presenceComplete(el)) {
      el.classList.remove("incomplete")
    } else {
      el.classList.add("incomplete")
    }
  }

  checkComplete() {
    if (this.presenceComplete(this.nameTarget) &&
        this.presenceComplete(this.streetAddressTarget) &&
        this.presenceComplete(this.localityTarget) &&
        this.presenceComplete(this.regionTarget) &&
        this.presenceComplete(this.postalCodeTarget)) {
      this.bannerTarget.style.visibility = "hidden"
    } else {
      this.bannerTarget.style.visibility = "visible"
    }
  }

  initialize() {
    [this.nameTarget,
     this.streetAddressTarget,
     this.localityTarget,
     this.regionTarget,
     this.postalCodeTarget].forEach(this.updatePresenceComplete, this)

    this.checkComplete()
  }
}
