import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "employeeDriverFields",
    "externalDriverFields",
    "employeeDriver",
    "externalDriver"
  ]

  initialize() {
    this.changeDriverType()
  }
  
  changeDriverType() {
    if (this.employeeDriverTarget.checked) {
      this.employeeDriverFieldsTarget.style.display = "block"
      this.externalDriverFieldsTarget.style.display = "none"
    } else if (this.externalDriverTarget.checked) {
      this.employeeDriverFieldsTarget.style.display = "none"
      this.externalDriverFieldsTarget.style.display = "block"
    } else {
      this.employeeDriverFieldsTarget.style.display = "none"
      this.externalDriverFieldsTarget.style.display = "none"
    }
  }
}
