import { Controller } from "stimulus"
import { parseDate } from "parse_date"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
    "banner",
    "caseNumber",
    "employee",
    "employeeFirstName",
    "employeeMiddleName",
    "employeeLastName",
    "employeeStreetAddress",
    "employeeLocality",
    "employeeRegion",
    "employeePostalCode",
    "employeeBirthDate",
    "employeeHireDate",
    "employeeGender",
    "employeeJobTitle",
    "supervisor",
    "takenToMedicalFacilityYes",
    "takenToMedicalFacilityNo",
    "physicianName",
    "medicalFacility",
    "medicalFacilitySummaryContainer",
    "employeeTakenToEmergencyRoomYes",
    "employeeTakenToEmergencyRoomNo",
    "employeeHospitalizedOvernightYes",
    "employeeHospitalizedOvernightNo",
    "establishment",
    "injuryType",
    "occurredOn",
    "timeEmployeeBeganWorkHour",
    "timeEmployeeBeganWorkMinute",
    "timeOfEventHour",
    "timeOfEventMinute",
    "timeOfEventCBD",
    "employeeWasDoingBeforeEvent",
    "whatHappened",
    "whatWasTheInjury",
    "didEmployeeDieYes",
    "didEmployeeDieNo",
    "dateOfDeath",
    "whereEventOccurred",
    "summaryOfEvent",
    "daysAwayFromWork",
    "daysOnJobRestrictions",
    "completedByName",
    "completedByJobTitle",
    "completedByPhoneNumber",
    "completedOn",
  ]

  // Condition for attributes where a value is required by OSHA.
  presenceComplete(el) {
    return el.value == "" ? false : true
  }

  boolPresenceComplete(yesEl, noEl) {
    return yesEl.checked || noEl.checked
  }

  // More complicated completness rules that depend on other values
  caseNumberComplete() {
    if (this.hasCaseNumberTarget) {
      return this.presenceComplete(this.caseNumberTarget)
    } else {
      return true
    }
  }

  // The medical facility description partial included a hidden attribute with the OSHA
  // recording completeness status of the facility
  isMedicalFacilityComplete() {
    return document.querySelector("[name='medical-facility-status']").value != 'incomplete'
  }
  
  medicalInfoComplete() {
    if (this.takenToMedicalFacilityNoTarget.checked) {
      return true
    } else {
      return this.takenToMedicalFacilityYesTarget.checked &&
        this.presenceComplete(this.physicianNameTarget) &&
        this.presenceComplete(this.medicalFacilityTarget) &&
        this.boolPresenceComplete(this.employeeTakenToEmergencyRoomYesTarget,
                                  this.employeeTakenToEmergencyRoomNoTarget) &&
        this.boolPresenceComplete(this.employeeHospitalizedOvernightYesTarget,
                                  this.employeeHospitalizedOvernightNoTarget) &&
	this.isMedicalFacilityComplete()
    }
  }

  timeOfEventComplete() {
    if (this.timeOfEventCBDTarget.checked) {
      return true
    } else {
      return this.presenceComplete(this.timeOfEventHourTarget) &&
        this.presenceComplete(this.timeOfEventMinuteTarget)
    }
  }

  didEmployeeDieComplete() {
    if (this.didEmployeeDieNoTarget.checked) {
      return true
    } else {
      return this.didEmployeeDieYesTarget.checked && this.presenceComplete(this.dateOfDeathTarget)
    }
  }


  // Actions
  changePresenceComplete(event) {
    this.updatePresenceComplete(event.target)
    this.checkComplete()
  }

  changeTimeOfEventCBD() {
    if (this.timeOfEventCBDTarget.checked) {
      this.timeOfEventHourTarget.value = ""
      this.timeOfEventMinuteTarget.value = ""
      this.timeOfEventHourTarget.disabled = true
      this.timeOfEventMinuteTarget.disabled = true
      this.timeOfEventHourTarget.classList.remove("incomplete")
      this.timeOfEventMinuteTarget.classList.remove("incomplete")
    } else {
      this.timeOfEventHourTarget.disabled = false
      this.timeOfEventMinuteTarget.disabled = false
      this.updatePresenceComplete(this.timeOfEventHourTarget)
      this.updatePresenceComplete(this.timeOfEventMinuteTarget)
    }
    $(this.timeOfEventHourTarget).trigger("chosen:updated")
    $(this.timeOfEventMinuteTarget).trigger("chosen:updated")

    this.checkComplete()
  }

  // We took away the ability to change employees on the edit form. This means that
  // the birth and hire data will always go to filling out hidden fields, which is
  // why we don't need to treat them like jquery datepickers
  changeEmployee() {
    this.updatePresenceComplete(this.employeeTarget)
    if (this.employeeTarget.value) {
      fetch(Routes.employee_path(this.employeeTarget.value, {account_id: this.element.dataset.accountId}), {credentials: "same-origin",
                                                                                                            headers: {
                                                                                                              "X-CSRF-Token": getCSRF()}})
        .then(response => response.text())
        .then(json => {
          let employee = JSON.parse(json)
          this.employeeFirstNameTarget.value = employee.first_name
          this.employeeMiddleNameTarget.value = employee.middle_name
          this.employeeLastNameTarget.value = employee.last_name
          this.employeeStreetAddressTarget.value = employee.street_address
          this.employeeLocalityTarget.value = employee.locality
          this.employeeRegionTarget.value = employee.region
          this.employeePostalCodeTarget.value = employee.postal_code
          $(this.employeeBirthDateTarget).datepicker("setDate", parseDate(employee.birth_date))
          $(this.employeeHireDateTarget).datepicker("setDate", parseDate(employee.hire_date))
          this.employeeGenderTarget.value = employee.gender
          this.employeeJobTitleTarget.value = employee.job_title

          if (this.hasSupervisorTarget) {
            if (employee.supervisor_id) {
              this.supervisorTarget.value = employee.supervisor_id
            } else {
              this.supervisorTarget.value = ""
            }
            $(this.supervisorTarget).trigger("chosen:updated")
          }

          $(this.employeeRegionTarget).trigger("chosen:updated")
          $(this.employeeGenderTarget).trigger("chosen:updated")

          ;[this.employeeFirstNameTarget,
            this.employeeLastNameTarget,
            this.employeeStreetAddressTarget,
            this.employeeLocalityTarget,
            this.employeeRegionTarget,
            this.employeePostalCodeTarget,
            this.employeeBirthDateTarget,
            this.employeeHireDateTarget,
            this.employeeGenderTarget,
            this.employeeJobTitleTarget].forEach(this.updatePresenceComplete, this)
          this.checkComplete()
        })
    } else {
      this.employeeFirstNameTarget.value = ""
      this.employeeMiddleNameTarget.value = ""
      this.employeeLastNameTarget.value = ""
      this.employeeStreetAddressTarget.value = ""
      this.employeeLocalityTarget.value = ""
      this.employeeRegionTarget.value = ""
      this.employeePostalCodeTarget.value = ""
      $(this.employeeBirthDateTarget).datepicker("setDate", null)
      $(this.employeeHireDateTarget).datepicker("setDate", null)
      this.employeeGenderTarget.value = ""
      this.employeeJobTitleTarget.value = ""

      if (this.hasSupervisorTarget) {
        this.supervisorTarget.value = ""
        $(this.supervisorTarget).trigger("chosen:updated")
      }

      $(this.employeeRegionTarget).trigger("chosen:updated")
      $(this.employeeGenderTarget).trigger("chosen:updated")

      ;[this.employeeFirstNameTarget,
        this.employeeLastNameTarget,
        this.employeeStreetAddressTarget,
        this.employeeLocalityTarget,
        this.employeeRegionTarget,
        this.employeePostalCodeTarget,
        this.employeeBirthDateTarget,
        this.employeeHireDateTarget,
        this.employeeGenderTarget,
        this.employeeJobTitleTarget].forEach(this.updatePresenceComplete, this)
      this.checkComplete()
    }
  }

  changeMedicalInfo() {
    this.updateBoolPresenceComplete(this.takenToMedicalFacilityYesTarget, this.takenToMedicalFacilityNoTarget)

    if (this.takenToMedicalFacilityYesTarget.checked) {
      document.querySelector(".medical-facility-fields").style.display = "block"
    } else {
      document.querySelector(".medical-facility-fields").style.display = "none"
    }

    if (this.medicalFacilityTarget.value) {
      document.querySelector(".medical-facility-summary").style.display = "block"
    } else {
      document.querySelector(".medical-facility-summary").style.display = "none"
    }

    this.updatePresenceComplete(this.physicianNameTarget)
    this.updatePresenceComplete(this.medicalFacilityTarget)
    this.updateBoolPresenceComplete(this.employeeTakenToEmergencyRoomYesTarget, this.employeeTakenToEmergencyRoomNoTarget)
    this.updateBoolPresenceComplete(this.employeeHospitalizedOvernightYesTarget, this.employeeHospitalizedOvernightNoTarget)

    this.checkComplete()
  }

  changeMedicalFacility(event) {
    if (event.target.value) {
      fetch(Routes.medical_facility_summary_serious_injuries_path({account_id: this.element.dataset.accountId,
								   medical_facility_id: event.target.value}),
	    {credentials: "same-origin",
	     headers: {
	       "X-CSRF-Token": getCSRF()}})
	.then(response => response.text())
	.then(html => {
	  this.medicalFacilitySummaryContainerTarget.style.display = "block"
          this.medicalFacilitySummaryContainerTarget.innerHTML = html
	  this.checkComplete()
	})
    } else {
      this.medicalFacilitySummaryContainerTarget.style.display = "none"
    }
  }

  changeDidEmployeeDie() {
    this.updateBoolPresenceComplete(this.didEmployeeDieYesTarget, this.didEmployeeDieNoTarget)

    if (this.didEmployeeDieYesTarget.checked) {
      document.querySelector(".date-of-death-fields").style.display = "block"
    } else {
      $(this.dateOfDeathTarget).datepicker("setDate", null)
      document.querySelector(".date-of-death-fields").style.display = "none"
    }

    this.updatePresenceComplete(this.dateOfDeathTarget)
    this.checkComplete()
  }

  // display methods
  updatePresenceComplete(el) {
    if (this.presenceComplete(el)) {
      el.classList.remove("incomplete")
    } else {
      el.classList.add("incomplete")
    }
  }

  updateBoolPresenceComplete(yesEl, noEl) {
    if (yesEl.checked || noEl.checked) {
      yesEl.classList.remove("incomplete")
      noEl.classList.remove("incomplete")
    } else {
      yesEl.classList.add("incomplete")
      noEl.classList.add("incomplete")
    }
  }


  checkComplete() {
    if (this.presenceComplete(this.employeeTarget) &&
        this.presenceComplete(this.employeeFirstNameTarget) &&
        this.presenceComplete(this.employeeLastNameTarget) &&
        this.presenceComplete(this.employeeStreetAddressTarget) &&
        this.presenceComplete(this.employeeLocalityTarget) &&
        this.presenceComplete(this.employeeRegionTarget) &&
        this.presenceComplete(this.employeePostalCodeTarget) &&
        this.presenceComplete(this.employeeBirthDateTarget) &&
        this.presenceComplete(this.employeeHireDateTarget) &&
        this.presenceComplete(this.employeeGenderTarget) &&
        this.presenceComplete(this.employeeJobTitleTarget) &&
        this.presenceComplete(this.establishmentTarget) &&
        this.presenceComplete(this.injuryTypeTarget) &&
        this.presenceComplete(this.occurredOnTarget) &&
        this.presenceComplete(this.timeEmployeeBeganWorkHourTarget) &&
        this.presenceComplete(this.timeEmployeeBeganWorkMinuteTarget) &&
        // these are addressed by timeOfEventComplete
        // this.presenceComplete(this.timeOfEventHourTarget) &&
        // this.presenceComplete(this.timeOfEventMinuteTarget) &&
        this.presenceComplete(this.employeeWasDoingBeforeEventTarget) &&
        this.presenceComplete(this.whatHappenedTarget) &&
        this.presenceComplete(this.whatWasTheInjuryTarget) &&
        this.presenceComplete(this.whereEventOccurredTarget) &&
        this.presenceComplete(this.summaryOfEventTarget) &&
        this.presenceComplete(this.daysAwayFromWorkTarget) &&
        this.presenceComplete(this.daysOnJobRestrictionsTarget) &&
        this.presenceComplete(this.completedByNameTarget) &&
        this.presenceComplete(this.completedByJobTitleTarget) &&
        this.presenceComplete(this.completedByPhoneNumberTarget) &&
	this.presenceComplete(this.completedOnTarget) &&
        this.didEmployeeDieComplete() &&
        this.medicalInfoComplete() &&
        this.timeOfEventComplete() &&
        this.caseNumberComplete()) {
      if (this.hasBannerTarget) {
        this.bannerTarget.style.visibility = "hidden"
      }
    } else {
      if (this.hasBannerTarget) {
        this.bannerTarget.style.visibility = "visible"
      }
    }
  }

  initialize() {
    [
      this.employeeTarget,
      this.employeeFirstNameTarget,
      this.employeeLastNameTarget,
      this.employeeStreetAddressTarget,
      this.employeeLocalityTarget,
      this.employeeRegionTarget,
      this.employeePostalCodeTarget,
      this.employeeBirthDateTarget,
      this.employeeHireDateTarget,
      this.employeeGenderTarget,
      this.employeeJobTitleTarget,
      this.establishmentTarget,
      this.injuryTypeTarget,
      this.occurredOnTarget,
      this.timeEmployeeBeganWorkHourTarget,
      this.timeEmployeeBeganWorkMinuteTarget,
      this.employeeWasDoingBeforeEventTarget,
      this.whatHappenedTarget,
      this.whatWasTheInjuryTarget,
      this.whereEventOccurredTarget,
      this.timeOfEventHourTarget,
      this.timeOfEventMinuteTarget,
      this.summaryOfEventTarget,
      this.daysAwayFromWorkTarget,
      this.daysOnJobRestrictionsTarget,
    ].forEach(this.updatePresenceComplete, this)

    if (this.hasCompletedByNameTarget) {
      this.updatePresenceComplete(this.completedByNameTarget)
    }
    if (this.hasCompletedByJobTitleTarget) {
      this.updatePresenceComplete(this.completedByJobTitleTarget)
    }
    if (this.hasCompletedByPhoneNumberTarget) {
      this.updatePresenceComplete(this.completedByPhoneNumberTarget)
    }
    if (this.hasCompletedOnTarget) {
      this.updatePresenceComplete(this.completedOnTarget)
    }

    // Support for historic osha log items
    if (this.hasCaseNumberTarget) {
      this.updatePresenceComplete(this.caseNumberTarget)
    }

    this.changeMedicalInfo()
    this.changeDidEmployeeDie()
    this.changeTimeOfEventCBD()

    this.checkComplete()
  }
}
