import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = [
    "newPost",
    "postTemplate",
    "posts",
    "postButton",
    "editDialog",
    "deleteDialog",
    "noPosts",
  ]

  initialize() {
    // jqueryUI will move these elements, cache where they go
    this.$editDialog = $(this.editDialogTarget)
    this.$deleteDialog = $(this.deleteDialogTarget)
    let model_name = this.element.dataset.modelName
    
    this.$editDialog.dialog({
      autoOpen: false,
      draggable: false,
      modal: true,
      closeText: "X",
      width: 600,
    })
    this.$editDialog.on("click", ".close", e => {
      e.preventDefault()
      this.$editDialog.dialog("close")
    })
    this.$editDialog[0].querySelector("button.edit-button").addEventListener("click", e => {
      let id = e.target.dataset.id
      let url = e.target.dataset.url

      fetch(url,
	    {
	      method: "PATCH",
	      credentials: "same-origin",
	      headers: {
		"X-CSRF-Token": getCSRF(),
		"Content-Type": "application/json",
	      },
	      body: JSON.stringify({
		[model_name]: {
		  id: id,
		  post: this.$editDialog.find("textarea").val(),
		}
	      })
	    })
	.then( response => {
	  if (response.ok) {
	    this.renderPosts()
	    this.sync()
	  }
	})
	.finally( () => {
	  this.$editDialog.dialog("close")
	}) 
    })

    this.$deleteDialog.dialog({
      autoOpen: false,
      draggable: false,
      modal: true,
      closeText: "X",
      width: 600,
    })
    this.$deleteDialog.on("click", ".close", e => {
      e.preventDefault()
      this.$deleteDialog.dialog("close")
    })
    this.$deleteDialog[0].querySelector("button.delete-button").addEventListener("click", e => {
      let id = e.target.dataset.id
      let url = e.target.dataset.url
      fetch(url,
	    {
	      method: "DELETE",
	      credentials: "same-origin",
	      headers: {
		"X-CSRF-Token": getCSRF(),
	      },
	    })
	.then( response => {
	  if (response.ok) {
	    this.renderPosts()
	    this.sync()
	  }
	})
	.finally( () => {
	  this.$deleteDialog.dialog("close")
	}) 
    })

    this.sync()
    this.renderPosts()
  }

  sync() {
    this.postButtonTarget.disabled = this.newPostTarget.value === ""
  }
  
  submit() {
    fetch(this.element.dataset.conversationUrl,
	  {
	    method: "POST",
	    body: JSON.stringify({
	      corrective_action_post: {
		post: this.newPostTarget.value,
	      },
	    }),
	    credentails: "same-origin",
	    headers: {
	      "X-CSRF-Token": getCSRF(),
	      "Content-Type": "application/json",
	    },
	  })
      .then( response => {
	if (response.ok) {
	  this.newPostTarget.value = ""
	  this.renderPosts()
	  this.sync()
	}
      })
  }

  renderPosts() {
    fetch(this.element.dataset.conversationUrl,
	  {
	    method: "GET",
	    credentials: "same-origin",
	    headers: {
	      "X-CSRF-Token": getCSRF(),
	    },
	  })
      .then( response => {
	if (response.ok) {
	  return response.json()
	}
      })
      .then( data => {
	this.postsTarget.innerHTML = ""
	if (data.length == 0) {
	  this.noPostsTarget.classList.remove("hidden")
	} else {
	  this.noPostsTarget.classList.add("hidden")
	  data.forEach( post => {
	    let newPost = this.postTemplateTarget.content.cloneNode(true)
	    newPost.querySelector(".post").textContent = post.post
	    newPost.querySelector(".posted-by").textContent = post.posted_by
	    newPost.querySelector(".posted-at").textContent = post.posted_at
	    
	    if (post.edited_at !== null) {
	      newPost.querySelector(".edited-by").textContent = post.edited_by
	      newPost.querySelector(".edited-at").textContent = post.edited_at
	    } else {
	      newPost.querySelector(".if-edited").style.display = "none"
	    }
	    
	    // Attach event handlers
	    if (post.edit_url !== null) {
	      newPost.querySelector(".edit-button").addEventListener("click", e => {
		this.showEditDialog(post.id, post.post, post.edit_url)
	      })
	    } else {
	      newPost.querySelector(".edit-button").style.display = "none"
	    }
	    
	    if (post.delete_url !== null) {
	      newPost.querySelector(".delete-button").addEventListener("click", e => {
		this.showDeleteDialog(post.id, post.post, post.delete_url)
	      })
	    } else {
	      newPost.querySelector(".delete-button").style.display = "none"
	    }
	    
	    this.postsTarget.appendChild(newPost)
	  })
	}
      })
  }

  showEditDialog(id, post, url) {
    let editDialog = this.$editDialog[0]
    editDialog.querySelector("textarea").value = post
    editDialog.querySelector("button.edit-button").dataset.url = url
    editDialog.querySelector("button.edit-button").dataset.id = id
    this.$editDialog.dialog("open")
  }

  showDeleteDialog(id, post, url) {
    let deleteDialog = this.$deleteDialog[0]
    deleteDialog.querySelector(".post").textContent = post
    deleteDialog.querySelector("button.delete-button").dataset.url = url
    deleteDialog.querySelector("button.delete-button").dataset.id = id
    this.$deleteDialog.dialog("open")
  }
}
