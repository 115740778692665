import { Controller } from "stimulus"
import { getCSRF } from "csrf"

export default class extends Controller {
  static targets = ["employee",
                    "supervisor",
                    "jobTitle",
                    "takenToMedicalFacilityYes",
                    "takenToMedicalFacilityNo"]

  changeEmployee() {
    if (this.employeeTarget.value) {
      fetch(Routes.employee_path(this.employeeTarget.value, {account_id: this.element.dataset.accountId}), {credentials: "same-origin",
                                                                                                            headers: {
                                                                                                              "X-CSRF-Token": getCSRF()}})
        .then(response => response.text())
        .then(json => {
          let employee = JSON.parse(json)
          this.jobTitleTarget.value = employee.job_title
          if (employee.supervisor_id) {
            this.supervisorTarget.value = employee.supervisor_id
          } else {
            this.supervisorTarget.value = ""
          }
          $(this.supervisorTarget).trigger("chosen:updated")
        })
    } else {
      this.jobTitleTarget.value = ""
      this.supervisorTarget.value = ""
      $(this.supervisorTarget).trigger("chosen:updated")
    }
  }

  changeMedicalInfo() {
    if (this.takenToMedicalFacilityYesTarget.checked) {
      document.querySelector(".medical-facility-fields").style.display = "block"
    } else {
      document.querySelector(".medical-facility-fields").style.display = "none"
    }
  }

  initialize() {
    this.changeMedicalInfo()
  }
}
