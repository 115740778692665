import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["agreeButton"]

  scroll(event) {
    if (event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight) {
      this.agreeButtonTarget.disabled = false
    }
  }

  initialize() {
    this.agreeButtonTarget.disabled = true
  }
}
