import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "admin",
    "accessScoping",
    "safetyManagementRole",
    "otherIncidentRole",
    "trainingRole",
    "correctiveActionAuditorRole",
    "checklistRole",
    "documentRole",
    "portalRole",
    "explanation",
    "hiddenAdminRoles", // template that will set all roles to admin when admin is checked
    "hiddenAdminContainer", // container where the hidden admin roles template will be placed when admin is checked
  ]

  initialize() {
    this.sync()
  }
  
  toggleAdmin() {
    this.sync()
  }

  sync() {
    if (this.adminTarget.checked) {
      let content = this.hiddenAdminRolesTarget.content
      this.hiddenAdminContainerTarget.appendChild(content.cloneNode(true))
      this.safetyManagementRoleTarget.disabled = true
      this.safetyManagementRoleTarget.style.display = "none"
      this.otherIncidentRoleTarget.disabled = true
      this.otherIncidentRoleTarget.style.display = "none"
      this.trainingRoleTarget.disabled = true
      this.trainingRoleTarget.style.display = "none"
      this.checklistRoleTarget.disabled = true
      this.checklistRoleTarget.style.display = "none"
      this.documentRoleTarget.disabled = true
      this.documentRoleTarget.style.display = "none"
      this.portalRoleTarget.disabled = true
      this.portalRoleTarget.style.display = "none"
      if (this.hasCorrectiveActionAucitorRoleTarget) {
	this.correctiveActionAuditorRoleTarget.disabled = true
	this.correctiveActionAuditorRoleTarget.style.display = "none"
      }
      if (this.hasExplanationTarget) {
	this.explanationTarget.style.display = "block"
      }
      if (this.hasAccessScopingTarget) {
	this.accessScopingTarget.style.display = "none"
      }
    } else {
      this.hiddenAdminContainerTarget.innerHTML = ""
      this.safetyManagementRoleTarget.disabled = false
      this.safetyManagementRoleTarget.style.display = "block"
      this.otherIncidentRoleTarget.disabled = false
      this.otherIncidentRoleTarget.style.display = "block"
      this.trainingRoleTarget.disabled = false
      this.trainingRoleTarget.style.display = "block"
      this.checklistRoleTarget.disabled = false
      this.checklistRoleTarget.style.display = "block"
      this.documentRoleTarget.disabled = false
      this.documentRoleTarget.style.display = "block"
      this.portalRoleTarget.disabled = false
      this.portalRoleTarget.style.display = "block"
      if (this.hasCorrectiveActionAucitorRoleTarget) {
	this.correctiveActionAuditorRoleTarget.disabled = false
	this.correctiveActionAuditorRoleTarget.style.display = "block"
      }
      if (this.hasExplanationTarget) {
	this.explanationTarget.style.display = "none"
      }
      if (this.hasAccessScopingTarget) {
	this.accessScopingTarget.style.display = "block"
      }
    }
  }
}
