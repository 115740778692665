import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "electronic300aSettings",
    "electronic300aWarning",
    "useForOshaYes",
    "useForOshaNo",
  ]

  initialize() {
    this.sync()
  }

  sync() {
    if (this.useForOshaYesTarget.checked) {
      this.electronic300aSettingsTarget.classList.remove("hidden")
      this.electronic300aWarningTarget.classList.remove("hidden")
    } else {
      this.electronic300aSettingsTarget.classList.add("hidden")
      this.electronic300aWarningTarget.classList.add("hidden")
    }
  }
}
