import { Controller } from "stimulus"

// Attach this controller to the form containing the filters
export default class extends Controller {
  static targets = [
    "filtersContainer",
    "clearTagsButton",
  ]
  
  initialize() {
    this.filtersContainerTarget.querySelectorAll("select").forEach( select => {
      $(select).chosen().change( event => {
        this.element.submit();
      });
    })

    this.clearTagsButtonTargets.forEach( button => {
      button.addEventListener("click", event => {
        event.preventDefault();
	this.filtersContainerTarget
          .querySelectorAll("select")
          .forEach(function (select) {
            select.value = "";
          });
        this.element.submit();
      });
    })
  }
}
