import { Controller } from "stimulus"

export default class extends Controller {
  toggle(event) {
	if (event.target.checked) {
	  var ok = confirm("Are you sure you want this document available on your Portal?\nBy making this document available on your Portal, anyone with access to your Portal will have access to this document.")
	  if (!ok) {
		event.target.checked = false
	  }
	}
  }
}
