import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
	'date',
	'hour',
	'minute',
	'hidden',
  ]

  connect() {
	let $el = $(this.dateTarget)
	let startYear = this.dateTarget.dataset.startYear
	let endYear = this.dateTarget.dataset.endYear
	$el.datepicker({
	  changeYear: true,
	  gotoCurrent: true,
	  dateFormat: "mm/dd/yy",
	  yearRange: `${startYear}:${endYear}`,
	  onClose: (newDate, picker) => {
		document.getElementById(picker.id).dispatchEvent(new Event("oshachange"))
	  }
	})
	this.updateDatetime(null)
  }
  
  updateDatetime(event) {
	const dt = this._getCurrentDatetime()
	this.hiddenTarget.value = dt ? dt.toISOString() : ''
  }

  _getCurrentDatetime() {
	const date = String(this.dateTarget.value)
	if (date === '') {
	  return undefined
	}

	const hour = parseInt(String(this.hourTarget.value))
	const minute = parseInt(String(this.minuteTarget.value))
	if (isNaN(hour) || isNaN(minute)) {
	  return undefined
	}

	const [
	  monthStr,
	  dateStr,
	  yearStr,
	] = date.split('/')

	return new Date(
	  parseInt(yearStr),
	  parseInt(monthStr) - 1,
	  parseInt(dateStr),
	  hour,
	  minute,
	)
  }
}
