import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "approveDenyButtons",
    "reasonForm",
  ]

  initialize() {
    this.cancelDenial()
  }
  
  startDenial(event) {
    this.approveDenyButtonsTarget.style.display = "none";
    this.reasonFormTarget.classList.add("denying")
    this.reasonFormTarget.scrollIntoView()
    this.reasonFormTarget.querySelector("textarea").focus();
  }
  
  cancelDenial(event) {
    if (event) {
      event.preventDefault()
    }
    this.approveDenyButtonsTarget.style.display = "flex";
    this.reasonFormTarget.classList.remove("denying")
  }
}
