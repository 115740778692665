// Same controller as nested_forms with a different name for embedding within a single element
// of the nested_forms_controller.
// Note that the constant to be replaced with the autogenerated ID is NEW_INNER_RECORD, not NEW_RECORD
// like in nested_forms_controller.

import { Controller } from "stimulus"
import { buildJqueryDatepicker } from "../datepickers"
import { buildChosen } from "../chosen"

export default class extends Controller {
  static targets = [
	"template",
	"list",
  ]

  add(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/NEW_INNER_RECORD/g, new Date().getTime())
    this.listTarget.insertAdjacentHTML("beforeend", content)

    // Turn the newly inserted selects into chosen selects
    this.listTarget.lastElementChild.querySelectorAll("select:not(.skip-chosen)").forEach(buildChosen)
    this.listTarget.lastElementChild.querySelectorAll(".datepicker").forEach(buildJqueryDatepicker)
  }

  remove(event) {
    event.preventDefault()
    let wrapper = event.target.closest(".nested-fields")
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = "none"
    }
  }
}
