import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["establishmentPicker",
					"checklistPicker",
					"fromPicker",
					"toPicker"]

  changeEstablishment(event) {
	this.getReport()
  }

  changeChecklist(event) {
	this.getReport()
  }
  
  changeDate(event) {
	this.getReport()
  }

  printPDF(event) {
	event.preventDefault()
	this.getReport("pdf")
  }
  
  getReport(format) {
	let accountId = this.data.get("accountId")
    let from = this.fromPickerTarget.value
    let to = this.toPickerTarget.value
    let establishmentOrDivisionId = this.establishmentPickerTarget.value
	let checklist = this.checklistPickerTarget.value
	let routeAttrs = {from_datepicker: from,
					  to_datepicker: to,
					  establishment_or_division: establishmentOrDivisionId,
					  checklist: checklist}
	if (format) {
	  routeAttrs.format = format
	}

	location.href = Routes.inspector_activity_report_path(accountId, routeAttrs)
  }
}
